var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central video-conf" },
    [
      _vm.loader
        ? _c("main-content-loader")
        : _c(
            "div",
            { staticClass: "videoconferences" },
            [
              _c(
                "main-content-container",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "items-list" },
                            [
                              !_vm.confPlanned.length
                                ? [
                                    _c("list-item", {
                                      staticClass: "vm-item no-confs",
                                      attrs: {
                                        title: "",
                                        footer: _vm.$t(
                                          "videomost.no-planned-conf"
                                        ),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.confPlanned.length
                                ? _vm._l(_vm.confPlanned, function (conf, i) {
                                    return _c("list-item", {
                                      key: i,
                                      staticClass: "vm-item",
                                      attrs: {
                                        title:
                                          conf.topic +
                                          " (" +
                                          _vm.confTypes[conf.type] +
                                          ")",
                                        footer:
                                          _vm.$t("settings.starts") +
                                          " " +
                                          _vm.getLocalTime(conf.startTime),
                                        link: "",
                                        chevron: "",
                                      },
                                      nativeOn: {
                                        contextmenu: function ($event) {
                                          return _vm.openContextMenu(
                                            $event,
                                            conf
                                          )
                                        },
                                        click: function ($event) {
                                          return _vm.confInfo(conf)
                                        },
                                      },
                                    })
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(_vm._s(_vm.$t("videomost.planned-conferences"))),
                  ]),
                ],
                2
              ),
              _vm.confPassed.length
                ? _c(
                    "main-content-container",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "items-list" },
                                  _vm._l(_vm.confPassed, function (conf, i) {
                                    return _c("list-item", {
                                      key: i,
                                      staticClass: "vm-item",
                                      attrs: {
                                        title:
                                          conf.topic +
                                          " (" +
                                          _vm.confTypes[conf.type] +
                                          ")",
                                        footer:
                                          _vm.$t("settings.starts") +
                                          " " +
                                          _vm.getLocalTime(conf.startTime),
                                        link: "",
                                        chevron: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.confInfo(conf)
                                        },
                                        contextmenu: function ($event) {
                                          return _vm.openContextMenu(
                                            $event,
                                            conf
                                          )
                                        },
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2175301819
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(_vm._s(_vm.$t("videomost.passed-conferences"))),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }