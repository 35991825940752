var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-settings" },
    [
      _c(
        "main-content-container",
        {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "items-list" },
                    [
                      _c(
                        "list-item",
                        { attrs: { title: _vm.$t("settings.run-at-start") } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-switch",
                              attrs: { slot: "after" },
                              slot: "after",
                            },
                            [
                              _c("custom-checkbox-slider", {
                                attrs: {
                                  checkbox: _vm.runByStart,
                                  default: _vm.runByStart,
                                },
                                on: {
                                  "update:checkbox": function ($event) {
                                    _vm.runByStart = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("settings.general")))]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }