var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-text" }, [
    _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }