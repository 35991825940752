var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title:
          _vm.type === "edit"
            ? _vm.$t("contact-editing")
            : _vm.$t("create-contact"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "contact-edition" }, [
          _c("div", { staticClass: "block" }, [
            _c(
              "div",
              {
                directives: [{ name: "focus", rawName: "v-focus" }],
                staticClass: "edition-user-cont",
              },
              [
                _c("div", { staticClass: "edition-user-main" }, [
                  _c(
                    "div",
                    { staticClass: "icon-wrapper edition-user-foto" },
                    [
                      _c("icon-image", {
                        staticClass: "medium-big editable",
                        attrs: {
                          src: _vm.photo,
                          remove: _vm.iconCanBeRemoved,
                          rollBack: _vm.iconCanBeRollback,
                        },
                        on: {
                          loaded: _vm.doOnImageLoad,
                          remove: _vm.doRemovePhoto,
                          rollBack: _vm.doRollbackPhoto,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.doFileSelect($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "edition-user-name" }, [
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.SURNAME]
                                .value,
                            expression:
                              "contact[CONTACT_FIELD_TYPES.SURNAME].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("information.surname") },
                        domProps: {
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.SURNAME].value,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.SURNAME],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.SURNAME)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue(
                                    _vm.CONTACT_FIELD_TYPES.SURNAME
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.NAME].value,
                            expression:
                              "contact[CONTACT_FIELD_TYPES.NAME].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("information.name") },
                        domProps: {
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.NAME].value,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.NAME],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.NAME)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue(
                                    _vm.CONTACT_FIELD_TYPES.NAME
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.SECONDNAME]
                                .value,
                            expression:
                              "contact[CONTACT_FIELD_TYPES.SECONDNAME].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          placeholder: _vm.$t("information.secondname"),
                        },
                        domProps: {
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.SECONDNAME]
                              .value,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.SECONDNAME],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.SECONDNAME)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue(
                                    _vm.CONTACT_FIELD_TYPES.SECONDNAME
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.contact[_vm.CONTACT_FIELD_TYPES.NIK]
                      ? _c("div", { staticClass: "edition-user-name-input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.NIK]
                                    .value,
                                expression:
                                  "contact[CONTACT_FIELD_TYPES.NIK].value",
                              },
                            ],
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("nick") },
                            domProps: {
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.NIK].value,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.NIK],
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("div", { staticClass: "global-value-btn" }, [
                            _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.NIK)
                              ? _c("i", {
                                  staticClass: "fas fa-times",
                                  on: {
                                    click: function ($event) {
                                      return _vm.doGetGlobalValue(
                                        _vm.CONTACT_FIELD_TYPES.NIK
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("information.organization"))),
                  ]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.ORGANIZATION]
                              .value,
                          expression:
                            "contact[CONTACT_FIELD_TYPES.ORGANIZATION].value",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        placeholder: _vm.$t("information.organization"),
                      },
                      domProps: {
                        value:
                          _vm.contact[_vm.CONTACT_FIELD_TYPES.ORGANIZATION]
                            .value,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.ORGANIZATION],
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.ORGANIZATION)
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue(
                                  _vm.CONTACT_FIELD_TYPES.ORGANIZATION
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("information.department"))),
                  ]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.OFFICE].value,
                          expression:
                            "contact[CONTACT_FIELD_TYPES.OFFICE].value",
                        },
                      ],
                      staticClass: "input",
                      attrs: { placeholder: _vm.$t("information.department") },
                      domProps: {
                        value:
                          _vm.contact[_vm.CONTACT_FIELD_TYPES.OFFICE].value,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.OFFICE],
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.OFFICE)
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue(
                                  _vm.CONTACT_FIELD_TYPES.OFFICE
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("information.position")))]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.POST].value,
                          expression: "contact[CONTACT_FIELD_TYPES.POST].value",
                        },
                      ],
                      staticClass: "input",
                      attrs: { placeholder: _vm.$t("information.position") },
                      domProps: {
                        value: _vm.contact[_vm.CONTACT_FIELD_TYPES.POST].value,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact[_vm.CONTACT_FIELD_TYPES.POST],
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.POST)
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue(
                                  _vm.CONTACT_FIELD_TYPES.POST
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.ROOM]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.room"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.ROOM]
                                      .value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.ROOM].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.room"),
                              },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.ROOM]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.ROOM],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.ROOM)
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.ROOM
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.WORKSTART]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("work-hours")))]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.WORKSTART
                                    ].value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.WORKSTART].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("work-start") },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.WORKSTART]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.WORKSTART
                                    ],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(
                                _vm.CONTACT_FIELD_TYPES.WORKSTART
                              )
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.WORKSTART
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.WORKFINISH]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.WORKSTART]
                                ? ""
                                : _vm.$t("work-hours")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.WORKFINISH
                                    ].value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.WORKFINISH].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("work-finish") },
                              domProps: {
                                value:
                                  _vm.contact[
                                    _vm.CONTACT_FIELD_TYPES.WORKFINISH
                                  ].value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.WORKFINISH
                                    ],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(
                                _vm.CONTACT_FIELD_TYPES.WORKFINISH
                              )
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.WORKFINISH
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.LEADER]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.head"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.LEADER]
                                      .value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.LEADER].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.head"),
                              },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.LEADER]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.LEADER],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.LEADER)
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.LEADER
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.HELPER]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.helper"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.HELPER]
                                      .value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.HELPER].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.helper"),
                              },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.HELPER]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.HELPER],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.HELPER)
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.HELPER
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE],
                  function (phone, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(_vm.CONTACT_FIELD_TYPES.PHONE, index)
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.PHONE
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.PHONE,
                                  index
                                ),
                                text: _vm.contact[
                                  _vm.CONTACT_FIELD_TYPES.PHONE
                                ][index].name,
                                idTextarea: "div-textarea-phone-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE][
                                      index
                                    ],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE][
                                  index
                                ].value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.PHONE][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            disabled: _vm.isDisabled(
                              _vm.CONTACT_FIELD_TYPES.PHONE,
                              index
                            ),
                            placeholder: _vm.$t("loginPage.remote"),
                          },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE][index]
                                .value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.PHONE][
                                  index
                                ],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(
                            _vm.CONTACT_FIELD_TYPES.PHONE,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.PHONE,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL],
                  function (mail, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(_vm.CONTACT_FIELD_TYPES.MAIL, index)
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.MAIL
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.MAIL,
                                  index
                                ),
                                text: _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][
                                  index
                                ].name,
                                idTextarea: "div-textarea-mail-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][
                                      index
                                    ],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][index]
                                  .value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.MAIL][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: { placeholder: "e-mail" },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][index]
                                .value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.MAIL][
                                  index
                                ],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(
                            _vm.CONTACT_FIELD_TYPES.MAIL,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.MAIL,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ],
                  function (icq, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(_vm.CONTACT_FIELD_TYPES.ICQ, index)
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.ICQ
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.ICQ,
                                  index
                                ),
                                text: _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][
                                  index
                                ].name,
                                idTextarea: "div-textarea-icq-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][
                                      index
                                    ],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][index]
                                  .value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.ICQ][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            disabled: _vm.isDisabled(
                              _vm.CONTACT_FIELD_TYPES.ICQ,
                              index
                            ),
                            placeholder: "icq",
                          },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][index]
                                .value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.ICQ][index],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.ICQ, index)
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.ICQ,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE],
                  function (site, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(_vm.CONTACT_FIELD_TYPES.SITE, index)
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.SITE
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.SITE,
                                  index
                                ),
                                text: _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][
                                  index
                                ].name,
                                idTextarea: "div-textarea-site-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][
                                      index
                                    ],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][index]
                                  .value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.SITE][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            disabled: _vm.isDisabled(
                              _vm.CONTACT_FIELD_TYPES.SITE,
                              index
                            ),
                            placeholder: _vm.$t("information.website"),
                          },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][index]
                                .value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.SITE][
                                  index
                                ],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(
                            _vm.CONTACT_FIELD_TYPES.SITE,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.SITE,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.ADDRESS],
                  function (address, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(
                            _vm.CONTACT_FIELD_TYPES.ADDRESS,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.ADDRESS
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.ADDRESS,
                                  index
                                ),
                                text: _vm.contact[
                                  _vm.CONTACT_FIELD_TYPES.ADDRESS
                                ][index].name,
                                idTextarea: "div-textarea-address-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.ADDRESS][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.ADDRESS
                                    ][index],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.ADDRESS][
                                  index
                                ].value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.ADDRESS][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            disabled: _vm.isDisabled(
                              _vm.CONTACT_FIELD_TYPES.ADDRESS,
                              index
                            ),
                            placeholder: _vm.$t("address"),
                          },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.ADDRESS][
                                index
                              ].value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.ADDRESS][
                                  index
                                ],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(
                            _vm.CONTACT_FIELD_TYPES.ADDRESS,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.ADDRESS,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.BIRTHDAY]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.birthday"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.BIRTHDAY
                                    ].value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.BIRTHDAY].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.birthday"),
                              },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.BIRTHDAY]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.BIRTHDAY
                                    ],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(
                                _vm.CONTACT_FIELD_TYPES.BIRTHDAY
                              )
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.BIRTHDAY
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.PARTNER]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.spouse"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.PARTNER]
                                      .value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.PARTNER].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.spouse"),
                              },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.PARTNER]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.PARTNER
                                    ],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(
                                _vm.CONTACT_FIELD_TYPES.PARTNER
                              )
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.PARTNER
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.contact[_vm.CONTACT_FIELD_TYPES.CHILDREN],
                  function (children, index) {
                    return _c("div", { staticClass: "edition-user-section" }, [
                      _c("div", { staticClass: "edition-user-section-label" }, [
                        _c("div", { staticClass: "edition-user-btn-set" }, [
                          !_vm.isDisabled(
                            _vm.CONTACT_FIELD_TYPES.CHILDREN,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-cog",
                                on: {
                                  click: function ($event) {
                                    return _vm.doMenu(
                                      $event,
                                      index,
                                      "bottom-right",
                                      _vm.CONTACT_FIELD_TYPES.CHILDREN
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-label-name" },
                          [
                            _c("custom-input", {
                              attrs: {
                                contenteditable: _vm.contenteditable(
                                  _vm.CONTACT_FIELD_TYPES.CHILDREN,
                                  index
                                ),
                                text: _vm.contact[
                                  _vm.CONTACT_FIELD_TYPES.CHILDREN
                                ][index].name,
                                idTextarea: "div-textarea-children-" + index,
                                typeField:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.CHILDREN][
                                    index
                                  ].type,
                              },
                              on: {
                                "update:text": function ($event) {
                                  return _vm.$set(
                                    _vm.contact[
                                      _vm.CONTACT_FIELD_TYPES.CHILDREN
                                    ][index],
                                    "name",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "edition-user-section-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.CHILDREN][
                                  index
                                ].value,
                              expression:
                                "contact[CONTACT_FIELD_TYPES.CHILDREN][index].value",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            disabled: _vm.isDisabled(
                              _vm.CONTACT_FIELD_TYPES.CHILDREN,
                              index
                            ),
                            placeholder: _vm.$t("information.children"),
                          },
                          domProps: {
                            value:
                              _vm.contact[_vm.CONTACT_FIELD_TYPES.CHILDREN][
                                index
                              ].value,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.contact[_vm.CONTACT_FIELD_TYPES.CHILDREN][
                                  index
                                ],
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("div", { staticClass: "global-value-btn" }, [
                          _vm.globalValueBtn(
                            _vm.CONTACT_FIELD_TYPES.CHILDREN,
                            index
                          )
                            ? _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.doGetGlobalValue(
                                      _vm.CONTACT_FIELD_TYPES.CHILDREN,
                                      index
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }
                ),
                _vm.contact[_vm.CONTACT_FIELD_TYPES.NOTE]
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("notes")))]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.NOTE]
                                      .value,
                                  expression:
                                    "contact[CONTACT_FIELD_TYPES.NOTE].value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("notes") },
                              domProps: {
                                value:
                                  _vm.contact[_vm.CONTACT_FIELD_TYPES.NOTE]
                                    .value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact[_vm.CONTACT_FIELD_TYPES.NOTE],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn(_vm.CONTACT_FIELD_TYPES.NOTE)
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          _vm.CONTACT_FIELD_TYPES.NOTE
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  staticStyle: { display: "none" },
                  attrs: { id: "inputFotoUpload", type: "file" },
                  on: { change: _vm.doAddImage },
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btns-service" }, [
          !_vm.localUser && _vm.cid !== _vm.uid
            ? _c(
                "div",
                {
                  staticClass: "btn reset-local",
                  attrs: { title: _vm.$t("reset-initial") },
                  on: { click: _vm.backToGlobal },
                },
                [_c("i", { staticClass: "fas fa-undo-alt" })]
              )
            : _vm._e(),
          _vm.localUser
            ? _c(
                "div",
                {
                  staticClass: "btn delete-local",
                  attrs: { title: _vm.$t("delete-contact") },
                  on: { click: _vm.deleteLocalUser },
                },
                [_c("i", { staticClass: "fa fa-trash-alt" })]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "btn add-field",
              attrs: { title: _vm.$t("add-field") },
              on: { click: _vm.addField },
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "btns-func" },
          [
            _c("modal-btn", {
              attrs: { text: _vm.$t("cancel") },
              on: { click: _vm.cancel },
            }),
            _c("modal-btn", {
              attrs: { text: _vm.$t("save") },
              on: { click: _vm.save },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }