var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon-block",
      on: {
        contextmenu: function ($event) {
          return _vm.$emit("contextmenu", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "icon-wrapper" }, [
          _vm.srcComputed
            ? _c("img", {
                staticClass: "photo",
                attrs: {
                  src: _vm.srcComputed,
                  alt: "",
                  ondrag: "return false",
                  ondragdrop: "return false",
                  ondragstart: "return false",
                },
                on: { load: _vm.doOnLoad, error: _vm.doOnError },
              })
            : (!_vm.src || _vm.get_error || _vm.get_error_global) && _vm.alter
            ? _c("div", { staticClass: "alter" }, [_vm._v(_vm._s(_vm.alter))])
            : _c("i", { staticClass: "alter fa fa-camera" }),
        ]),
      ]),
      _vm.marked
        ? _c("div", { staticClass: "marked" }, [
            _c("i", { staticClass: "far fa-check" }),
          ])
        : _vm._e(),
      _vm.rollBackIcon
        ? _c(
            "div",
            {
              staticClass: "roll-back",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("rollBack")
                },
              },
            },
            [_c("i", { staticClass: "far fa-undo-alt" })]
          )
        : _vm.removeIcon
        ? _c(
            "div",
            {
              staticClass: "remove",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("remove")
                },
              },
            },
            [_c("i", { staticClass: "far fa-times" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }