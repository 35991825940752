var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list" },
    [
      _vm._l(_vm.chats, function (chat) {
        return _vm.chats.length
          ? _c(
              "li",
              { staticClass: "listItemStyle" },
              [_c("chat-item", { attrs: { chat: chat } })],
              1
            )
          : _vm._e()
      }),
      !_vm.chats.length
        ? _c("li", { staticClass: "itemNoChats" }, [
            _vm._v(_vm._s(_vm.$t("no-chats"))),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }