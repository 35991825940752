'use strict'
import Vue from 'vue'

import {
    GET_USER_PARAMS,
    GET_TETRA_PARAMS,
    GET_TETRA_CONNECTION_PARAMS,
    TETRA_IS_TURN_ON,
    GET_TETRA_CONNECT_STATUS,
    GET_TETRA_CONNECT_ERROR,
    GET_TETRA_LINK_STATUS,
    GET_TETRA_CELL,
    GET_TETRA_GROUPS,
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_PTT_PUSHED,
    GET_TETRA_CALL,
    GET_IS_ELECTRON,
    GET_CLIENT_VERSION,
    GET_TETRA_ECHO,
    GET_TETRA_TOKEN,
    GET_TETRA_SEARCHED_USERS,
    GET_TETRA_USERS_LIST,
    GET_TETRA_GROUP_USERS,
    GET_TETRA_USER_STATUS,
    GET_TETRA_USER_INFO,
    GET_TETRA_MESSAGES,
    GET_TETRA_MESSAGE_STATUS,
    GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT,
    GET_TETRA_UNWATCHED_MESSAGES_COUNT,
    GET_TETRA_IS_CONNECTED,
    GET_TETRA_OPENED_CHAT_SSI,
    GET_TETRA_DUPLEX_CALLS,
    GET_TETRA_DUPLEX_CALL_BY_SSI,
    GET_ANSWERED_CALLS_BY_TYPE,
    GET_ANSWERED_CALL,
    GET_TETRA_ACTIVE_DUPLEX_CALL,
} from '../gettersTypes'
import {
    ACT_TETRA_API_SEND_LINK_CMD,
    ACT_TETRA_API_SEND_SSI_CMD,
    ACT_TETRA_API_SEND_CMD,
    ACT_TETRA_CONNECT,
    ACT_TETRA_DISCONNECT,
    ACT_TETRA_CONNECT_STATE_TOGGLE,
    ACT_TETRA_GET_STATUS,
    ACT_TETRA_LINK_CREATE,
    ACT_TETRA_START_LINK_TIMER,
    ACT_TETRA_STOP_LINK_TIMER,
    ACT_TETRA_LINK_PING,
    ACT_TETRA_LOCATE,
    ACT_TETRA_GET_ATTACH_GROUPS,
    ACT_TETRA_GET_GROUPS_LIST,
    ACT_TETRA_ACTIVATE_GROUP,
    ACT_TETRA_PUSH_PTT,
    ACT_TETRA_POP_PTT,
    ACT_TETRA_EXIT_BTN,
    ACT_TETRA_HANDLE_MSG_EVENT,
    ACT_TETRA_HANDLE_STATUS_EVENT,
    ACT_TETRA_SEND_SPEECH,
    ACT_TETRA_SET_ECHO,
    ACT_TETRA_REQUEST_TOKEN,
    ACT_TETRA_GET_PROTO,
    ACT_TETRA_GET_GROUP_USERS,
    ACT_TETRA_SEARCH_USERS,
    ACT_TETRA_UPDATE_USERS_LIST,
    ACT_TETRA_ADD_RECENT_USER,
    ACT_TETRA_GET_USER_STATUS,
    ACT_TETRA_MESSAGE,
    ACT_TETRA_ADD_MESSAGE,
    ACT_ADD_NOTIFICATION,
    ACT_TETRA_STORE_MESSAGES,
    ACT_TETRA_RESTORE_MESSAGES,
    ACT_TETRA_MAKE_DUPLEX_CALL,
    ACT_TETRA_END_DUPLEX_CALL,
    ACT_ADD_INCOMMING_CALL,
    ACT_TETRA_ANSWER_DUPLEX_CALL,
    ACT_SET_ANSWERED_CALL_STATUS,
    ACT_TETRA_HANDLE_DUPLEX_CALL_EVENT,
    ACT_TERMINATE_CALL,
    ACT_HIDE_INCOMMING_CALL,
    ACT_TETRA_TERMINATE_DUPLEX_CALL,
    ACT_TETRA_PAUSE,
    ACT_TETRA_SEARCH_USER_BY_SSI,
    ACT_TETRA_SELECT_DUPLEX_CALL,
    ACT_TETRA_TRANSIT, ACT_TETRA_TOGGLE_MICROPHONE,
} from '../actionsTypes'
import {
    MUT_TETRA_SET_CLIENT_PARAMS,
    MUT_TETRA_SET_CONNECT_STATUS,
    MUT_TETRA_SET_LINK_STATUS,
    MUT_TETRA_SET_LINK_CREATED,
    MUT_TETRA_SET_LOCATED,
    MUT_TETRA_SET_REJECTED,
    MUT_TETRA_SET_GROUPS_ATTACHED,
    MUT_TETRA_SET_CALL,
    MUT_TETRA_SET_TX_GRANT,
    MUT_TETRA_SET_GROUPS,
    MUT_TETRA_SET_ACTIVE_GROUP,
    MUT_TETRA_SET_PTT_STATUS,
    MUT_TETRA_SET_CONNECT_ERROR,
    MUT_TETRA_SET_TOKEN,
    MUT_TETRA_SET_SEARCHED_USERS,
    MUT_TETRA_UPDATE_USERS_LIST,
    MUT_TETRA_ADD_RECENT_USER,
    MUT_TETRA_SET_GROUP_USERS,
    MUT_TETRA_SET_USER_STATUS,
    MUT_TETRA_ADD_MESSAGE,
    MUT_TETRA_SET_MESSAGE_STATUS,
    MUT_TETRA_CLEAR_UNWATCHED_MESSAGES,
    MUT_TETRA_SET_OPENED_CHAT_SSI,
    MUT_TETRA_ADD_DUPLEX_CALL,
    MUT_TETRA_REMOVE_DUPLEX_CALL,
    MUT_TETRA_RESTORE_MESSAGES,
} from '../mutationsTypes'

import {
    USERDATA,
    CLIENTDATA,
    NOTIFICATIONS,
    PHONE_CNTL,
} from '../modulesNames'

import event_bus from "../../eventBus"

const R = require('ramda')

import {ANSWERED_CALL_STATUSES, PHONE_TYPES} from '../../constants'

const linkDefines = {
    ssiStates: {
        unknown: "unknown",
        opened: "opened"
    },
    readyMsgs: {
        ready: "ready",
        notValidSystem: "notValidSystem",
        readyAck: "readyAck"
    },
    linkCloseCodes: {
        sockedClosed: {code: 1, text: "socket closed"},
        linkTestTimeout: {code: 2, text: "link test timeout"},
        systemNotServed: {code: 3, text: "system not served"},
        linkToSystemAbsent: {code: 4, text: "link to system absent"},
        noActiveSystemServer: {code: 5, text: "no link to active system"},
        invalidSSI: {code: 6, text: "invalid ssi for system"},
        invalidSystemOrSSI: {code: 7, text: "invalid system or ssi"},
        ssiIsAlreadyBusy: {code: 8,text: "ssi is already busy with another client"},
        invalidLife: {code: 9,text: "invalid life"},
        waitingLinkAnswer:    {code: 10, text: "waiting create link answer"},
        linkIsClosed: {code: 11,text: "link is closed"}
    }
}

const ssiDefines = {
        codecType: {  //for ct in speech messages
            g711: 0,        //g711 (a-law, 8000, 8-bit)
            tetra: 1
        },
        callDir: {
            outgoing: "outgoing",
            incoming: "incoming"
        },
        callType: {
            group: "group",
            duplex: "duplex",
            simplex: "simplex"
        },
}

export const CONNECT_STATUS = {
    OFFLINE:    'offline',
    CONNECTING: 'connecting',
    READY:      'ready',
    ERROR:      'error',
}

export const CONNECT_ERROR = {
    BUSY:                   'busy',
    INVALID_SERVER_NAME:    'invalid-server-name',
    INVALID_SSI:            'invalid-ssi',
    DISCONNECTED:           'tsystem-disconnected',
    TOKEN_EXPIRED:          'token-expired',
    UNKNOWN:                'unknown',
}

const STATUS = {
    READY:          'ready',
    DISCONNECTED:   'tsystem-disconnected',
    DROPPED:        'connection-dropped',
}

const MSG_LEVELS = {
    LINK:   'link',
    SSI:    'ssi',
    READY:  'ready',
}

const LINK_MSGS = {
    CREATE: "create",  //in parms - userId, clientAgent: {type,name}, allSSIEventsTrace
                       //        userId - string
                       //        clientAgent type -  string. Recommended: "HTML","App","Electron",...
                       //        clientAgent name -  string. Recommended: various with version
                       //        allSSIEventsTrace - true or false
    TEST:   "test",    //in parms - allSSIEventsTrace
    CLOSED: "closed",  //in parms - code,text from linkCloseCodes
    OPENED: "opened"   //in parms acp - audit parms about location
                       //and call state. Only in answer of test.
}

export const LINK_STATUS = {
    DISCONNECTED:   'disconnected',
    CREATING:       'creating',
    CLOSED:         'closed',
    OPENED:         'opened',
    ERROR:          'error',
}

const SSI_MSGS = {
    //commands:
    LOCATE:             "locate",           //*** after link created - necessarily,
                                            //answers: located, and then groupsAttached with
                                            //groups list, or groupsNotAttached with group list
    ATTACH_GROUPS:      "attachGroups",     //*** after located and not null group list
    ACTIVE_GROUPS:      "activateGroup",    //parms: group - group for calling with PTT
                                            //*** answer with groupsList,located and then -
                                            //groupAttached or groupsNotAttached event
    GET_GROUP_LIST:     "getGroupList",     //any tyme
    GET_GROUP_USERS:    "getGroupUsers",    //
    GET_USERS_LIST:     "getUsersList",     //
    GET_USER_STATUS:    "getUserStatus",    //
    SEARCH_USERS:       "searchUsers",      //
    MESSAGE:            "message",          //
    PUSH_PTT:           "pushPTT",          //parms: pingIndex (for calculate ping time from first answer)
                                            //*** ptt button pushed
    POP_PTT:            "popPTT",           //*** ptt button poped
    EXIT_BTN:           "exitButton",       //***or "red button", exit,escape,close...
    SPEECH:             "speech",           //parms: ct - codec type
                                            //       sd - speech data (symbols)
    DUPLEX_CALL:        "duplexCall"        // parms: ssi, callEvent
}

const SSI_EVENTS = {
                                                    //events:
    TRACE:                  "trace",                //parms: data - trace string for printf
    LOCATED:                "located",              //parms: cell - located cell if loacted in system, answer after locate
                                                    //       request, or any time from system. Then may be groupsAttached or
                                                    //       groupsNotAttached
    REJECTED:               "rejected",             //***locate rejected in system
    UNLOCATED:              "unLocated",            //temporary if some broken, no need command "locate",
                                                    //only wait events "located","rejected"
    GROUPS_ATTACHED:        "groupsAttached",       //ok after locate or "attachGroups"
                                                    //parms: l - groups list
    GROUPS_NOT_ATTACHED:    "groupsNotAttached",    //nok after locate or "attachGroups"
                                                    //parms: l - group list
    GROUP_LIST:             "groupList",            //l:
                                                    // cnt    - groups cnt or 0
                                                    // actual - actual group number, if 0 - absent
                                                    // list{} - list of group numbers (group number - index):
                                                    //           cofu - group scanning and selection parameter
                                                    //           name - name of group
    GROUP_LIST_CHANGED:     "groupListChanged",     //l: as in groupList, relocate needed after this...
    CALL_EVENT:             "callEvent",            // cp - call parms:
                                                    //   callEvent      - call event from callEvents
                                                    //   dir            - direction  from callDir
                                                    //   callType       - type of call from callType
                                                    //   groupSSI       - called,calling group SSI
                                                    //   txSSI          - current granted SSI
                                                    //   txSSIName      - current granted SSI Name
                                                    //   txGrant        - grant from txGrant
                                                    //   pttAllowed      - true, if ptt alowed in call
                                                    //   releaseCause   - release cause from releaseCause
                                                    //   pingIndex
                                                    //speech:
    SPEECH:                 "speech",               //parms: ct - codec type
                                                    //       sd - speech data (symbols)
    SEARCH_USERS:           "searchUsersResult",
    USERS_LIST:             "usersList",
    GROUP_USERS:            "groupUsers",
    USER_STATUS:            "userStatus",
    MESSAGE_STATUS:         "messageStatus",
    MESSAGE:                "message",
}

export const CALL_TYPES = {
    GROUP:      'group',
    DUPLEX:     'duplex',
    SIMPLEX:    'simplex',
}

export const CALL_EVENTS = {
    STARTING:           "starting",
    RELEASE:            "release",
    TX_GRANT:           "txGrant",
    TX_INTERRUPT:       "txInterrupt",
    CEASING:            "ceasing",
    RINGING:            "ringing",
    ANSWER:             "answer",
    TRANSFER:           "transfer",
}

export const CALL_DIRECTION = {
    OUTGOING: 'outgoing',
    INCOMING: 'incoming'
}

export const TX_GRANT = {
    GRANTED:            0,
    NOT_GRANTED:        1,
    REQUEST_QUEUED:     2,
    TO_ANOTHER_USER:    3
}

export const RELEASE_CAUSE = {
    USER:                   1,
    DECLINE:                2,
    TIMEOUT:                3,
    SYSTEM:                 4,
    LOCAL:                  5,
    SERVICE_NOT_AVAILABLE:  8,
}

export const COFU =  { //group scanning and selection
    NON_SCANNED_SELECTABLE: 1,
    SCANNED_SELECTABLE:     5,
    SCANNED_NON_SELECTABLE: 7
}

export const MSG = {
    SDS:    'sds',
    IN:     'in',
    OUT:    'out',
}

const SSI_SEARCH_AWAITING = {}

const state = {
    name: null,
    life: null,
    ssi: null,
    connectStatus: CONNECT_STATUS.OFFLINE,
    connectError: null,
    linkStatus: LINK_STATUS.DISCONNECTED,
    linkCreated: false,
    located: false,
    cell: 0,
    rejected: false,
    call: null,
    txGrant: -1,
    groupsAttached: false,
    activeGroup: null,
    groups: {},
    pttPushed: false,
    pingInterval: null,
    echo: 0,
    token: '',
    searchedUsers: [],
    usersStatuses: {},
    usersList: {},
    groupUsers: {},
    openedChatSSI: 0,
    messages: {},
    unwatchedMessages: {},
    duplexCalls: [],
}

const getters = {
    getRadioServer: (state, getters, rootState, rootGetters) =>  {
        let server = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`].radioServerV4 || ''
        if (server) server = `https://${server}`
        return server
    },
    [GET_TETRA_PARAMS]: (state, getters, rootGetters) => {
        const params = rootGetters[USERDATA][GET_USER_PARAMS]
        return params && params.phones && params.phones.tetra
    },
    [GET_TETRA_CONNECTION_PARAMS]: (state) => {
        return {
            name: state.name,
            life: state.life,
            ssi: state.ssi
        }
    },
    [TETRA_IS_TURN_ON]: (state) => {
        return state.connectStatus !== CONNECT_STATUS.OFFLINE
    },
    [GET_TETRA_CONNECT_STATUS]: (state) => {
        return state.connectStatus
    },
    [GET_TETRA_CELL]: (state) => {
        return state.cell
    },
    [GET_TETRA_CONNECT_ERROR]: (state) => {
        return state.connectError
    },
    [GET_TETRA_LINK_STATUS]: (state) => {
        return state.linkStatus
    },
    [GET_TETRA_GROUPS]: (state) => {
        return state.groups
    },
    [GET_TETRA_ACTIVE_GROUP]: (state) => {
        return state.activeGroup && state.groups[state.activeGroup] && {...{gssi: state.activeGroup}, ...state.groups[state.activeGroup]}
    },
    [GET_TETRA_PTT_PUSHED]: (state) => {
        return state.pttPushed
    },
    [GET_TETRA_CALL]: (state) => {
        return (!state.pttPushed && state.call && state.call.callEvent === CALL_EVENTS.RELEASE) ? null : state.call
    },
    [GET_TETRA_ECHO]: (state) => {
        return state.echo
    },
    [GET_TETRA_TOKEN]: (state) => {
        return state.token
    },
    [GET_TETRA_SEARCHED_USERS]: (state) => {
        return state.searchedUsers
    },
    [GET_TETRA_USERS_LIST]: (state) => (type = 'contact') => {
        return state.usersList[type]
    },
    [GET_TETRA_USER_INFO]: (state) => (ssi, type) => {
        return state.usersList[type] && state.usersList[type].find(user => user.ssi === ssi)
    },
    [GET_TETRA_GROUP_USERS]: (state) => (gssi) => {
        return state.groupUsers[gssi]
    },
    [GET_TETRA_USER_STATUS]: (state) => (ssi) => {
        return state.usersStatuses[ssi]
    },
    [GET_TETRA_MESSAGES]: (state) => (ssi) => {
        if (ssi) return state.messages[ssi]
        else return state.messages
    },
    [GET_TETRA_MESSAGE_STATUS]: (state) => (ssi) => {
        return state.messages[ssi]
    },
    [GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT]: (state) => (ssi) => {
        return ssi && state.unwatchedMessages[ssi] && state.unwatchedMessages[ssi].count
    },
    [GET_TETRA_UNWATCHED_MESSAGES_COUNT]: (state) => {
        return Object.keys(state.unwatchedMessages).reduce((a, k) => { return a + state.unwatchedMessages[k].count || 0 }, 0)
    },
    [GET_TETRA_IS_CONNECTED]: (state, getters) => {
        return getters[GET_TETRA_CONNECT_STATUS] !== CONNECT_STATUS.OFFLINE && getters[GET_TETRA_LINK_STATUS] === LINK_STATUS.OPENED
    },
    [GET_TETRA_OPENED_CHAT_SSI]: (state) => {
        return state.openedChatSSI
    },
    [GET_TETRA_DUPLEX_CALLS]: (state, getters, rootState, rootGetters) => {
        return rootGetters[`${PHONE_CNTL}/${GET_ANSWERED_CALLS_BY_TYPE}`](PHONE_TYPES.TETRA)
    },
    [GET_TETRA_ACTIVE_DUPLEX_CALL]: (state, getters) => {
        return getters[GET_TETRA_DUPLEX_CALLS].find(call => call.active)
    },
    [GET_TETRA_DUPLEX_CALL_BY_SSI]: (state, getters, rootState, rootGetters) => (ssi) => {
        return rootGetters[`${PHONE_CNTL}/${GET_ANSWERED_CALL}`](PHONE_TYPES.TETRA, ssi)
    },
}

const actions = {
    // ------------------ Abstract actions --------------------------
    [ACT_TETRA_CONNECT]: () => {},
    [ACT_TETRA_DISCONNECT]: () => {},
    [ACT_TETRA_GET_PROTO]: () => {},
    [ACT_TETRA_API_SEND_CMD]: () => {},
    [ACT_TETRA_GET_STATUS]: () => {},
    [ACT_TETRA_STORE_MESSAGES]: () => {},
    [ACT_TETRA_REQUEST_TOKEN]: () => {},
    // --------------------------------------------------------------
    [ACT_TETRA_API_SEND_LINK_CMD]: ({dispatch}, payload) => {
        return dispatch(ACT_TETRA_API_SEND_CMD, {...payload, ...{level: MSG_LEVELS.LINK}})
    },
    [ACT_TETRA_API_SEND_SSI_CMD]: ({dispatch}, payload) => {
        return dispatch(ACT_TETRA_API_SEND_CMD, {...payload, ...{level: MSG_LEVELS.SSI}})
    },
    [ACT_TETRA_CONNECT_STATE_TOGGLE]: async ({getters, dispatch}, payload) => {
        const isSocketReconnected = payload.hasOwnProperty('isSocketReconnected')
        if (getters[TETRA_IS_TURN_ON] && !isSocketReconnected) dispatch(ACT_TETRA_DISCONNECT)
        else {
            if (!isSocketReconnected) payload.pushedByBtn = true
            dispatch(ACT_TETRA_CONNECT, payload)
        }
    },
    [ACT_TETRA_LINK_CREATE]: async ({state, dispatch, commit, rootGetters}, payload) => {
        commit(MUT_TETRA_SET_LINK_STATUS, LINK_STATUS.CREATING)
        dispatch(ACT_TETRA_START_LINK_TIMER)
        const parms = {
            userId: state.ssi,
            clientAgent: {
                type: rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] ? 'Electron' : 'HTML',
                name: (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] ? '' : 'RosChat ') + rootGetters[`${CLIENTDATA}/${GET_CLIENT_VERSION}`]
            }
        }
        dispatch(ACT_TETRA_API_SEND_LINK_CMD, {msgType: LINK_MSGS.CREATE, parms})
    },
    [ACT_TETRA_LINK_PING]: async ({dispatch}) => {
        dispatch(ACT_TETRA_API_SEND_LINK_CMD, {msgType: LINK_MSGS.TEST})
    },
    [ACT_TETRA_START_LINK_TIMER]: async ({state, dispatch}) => {
        if (state.pingInterval) clearInterval(state.pingInterval)
        if (state.connectStatus !== CONNECT_STATUS.READY) return
        state.pingInterval = setInterval(() => {
            switch (state.linkStatus) {
                case LINK_STATUS.OPENED:
                    dispatch(ACT_TETRA_LINK_PING)
                    break
                case LINK_STATUS.CLOSED:
                case LINK_STATUS.ERROR:
                    dispatch(ACT_TETRA_LINK_CREATE)
            }
        },10000)
    },
    [ACT_TETRA_STOP_LINK_TIMER]: async ({state}) => {
        clearInterval(state.pingInterval)
    },
    [ACT_TETRA_LOCATE]: async ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.LOCATE})
    },
    [ACT_TETRA_GET_ATTACH_GROUPS]: async ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.ATTACH_GROUPS})
    },
    [ACT_TETRA_GET_GROUPS_LIST]: async ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.GET_GROUP_LIST})
    },
    [ACT_TETRA_ACTIVATE_GROUP]: async ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.ACTIVE_GROUPS, parms: payload})
    },
    [ACT_TETRA_GET_GROUP_USERS]: ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.GET_GROUP_USERS, parms: payload})
    },
    [ACT_TETRA_SEARCH_USERS]: ({dispatch}, parms) => {
        // console.log("🚀 ~ file: tetra-radio.js:442 ~ [ACT_TETRA_SEARCH_USERS]: ~ parms:", parms)
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.SEARCH_USERS, parms})
    },
    [ACT_TETRA_UPDATE_USERS_LIST]: ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.GET_USERS_LIST, parms: payload})
    },
    [ACT_TETRA_ADD_RECENT_USER]: ({commit}, payload) => {
        commit(MUT_TETRA_ADD_RECENT_USER, payload)
    },
    [ACT_TETRA_GET_USER_STATUS]: ({dispatch}, ssi) => {
        // console.log("🚀 ~ file: tetra-radio.js:471 ~ [ACT_TETRA_GET_USER_STATUS]: ~ ssi:", ssi)
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.GET_USER_STATUS, parms: {ssi}})
    },
    [ACT_TETRA_MESSAGE]: ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.MESSAGE, parms: payload})
    },
    [ACT_TETRA_ADD_MESSAGE]: ({commit}, message) => {
        commit(MUT_TETRA_ADD_MESSAGE, message)
    },
    [ACT_TETRA_RESTORE_MESSAGES]:({commit}, payload) => {
        commit(MUT_TETRA_RESTORE_MESSAGES)
    },
    [ACT_TETRA_PUSH_PTT]: async ({commit, dispatch}) => {
        commit(MUT_TETRA_SET_PTT_STATUS, true)
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.PUSH_PTT})
    },
    [ACT_TETRA_POP_PTT]: async ({commit, dispatch}) => {
        commit(MUT_TETRA_SET_PTT_STATUS, false)
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.POP_PTT})
    },
    [ACT_TETRA_EXIT_BTN]: async ({dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.EXIT_BTN})
    },
    [ACT_TETRA_SEND_SPEECH]: async ({state, commit, dispatch}, payload) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {msgType: SSI_MSGS.SPEECH, parms: payload})
    },
    [ACT_TETRA_SET_ECHO]: async ({state, commit}, payload) => {
        state.echo = Math.random()
    },
    [ACT_TETRA_HANDLE_STATUS_EVENT]: async ({state, dispatch, commit}, {status}) => {
        switch (status) {
            case STATUS.READY:
                if (state.connectStatus === status) return
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.READY)
                dispatch(ACT_TETRA_LINK_CREATE)
                break
            case STATUS.DROPPED:
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.OFFLINE)
            case STATUS.DISCONNECTED:
                if (status === STATUS.DISCONNECTED) {
                    commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.ERROR)
                    commit(MUT_TETRA_SET_CONNECT_ERROR, status)
                }
                commit(MUT_TETRA_SET_LINK_CREATED)
                commit(MUT_TETRA_SET_LINK_STATUS)
                commit(MUT_TETRA_SET_GROUPS, {})
                commit(MUT_TETRA_SET_CALL)
                break
        }
    },
    [ACT_TETRA_HANDLE_MSG_EVENT]: async ({state, dispatch, commit, getters}, {a, level, msgType, parms}) => {
        switch (level) {
            case MSG_LEVELS.LINK:
                if (state.linkStatus !== msgType) commit(MUT_TETRA_SET_LINK_STATUS, msgType)
                switch (msgType) {
                    case LINK_MSGS.OPENED:
                        const {lp, isCall} = parms.acp || {}
                        const { located, cell } = lp || {}
                        if (located) commit(MUT_TETRA_SET_LOCATED, {state: located, cell})
                        else dispatch(ACT_TETRA_LOCATE)
                        break
                    case LINK_MSGS.CLOSED:
                        dispatch(ACT_TETRA_DISCONNECT)
                        break
                }
                break
            case MSG_LEVELS.SSI:
                if (msgType !== SSI_EVENTS.SPEECH) console.log(JSON.stringify({a, level, msgType, parms}))
                switch (msgType) {
                    case SSI_EVENTS.TRACE:
                        console.log(parms.data)
                        break
                    case SSI_EVENTS.LOCATED:
                        commit(MUT_TETRA_SET_LOCATED, {state: true, cell: parms.cell})
                        break
                    case SSI_EVENTS.REJECTED:
                        commit(MUT_TETRA_SET_REJECTED, true)
                        break
                    case SSI_EVENTS.UNLOCATED:
                        if (state.located && !state.rejected) commit(MUT_TETRA_SET_LOCATED, {state: false})
                        break
                    case SSI_EVENTS.GROUPS_ATTACHED:
                        commit(MUT_TETRA_SET_GROUPS_ATTACHED, true)
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.GROUPS_NOT_ATTACHED:
                        commit(MUT_TETRA_SET_GROUPS_ATTACHED)
                        break
                    case SSI_EVENTS.USERS_LIST:
                        commit(MUT_TETRA_UPDATE_USERS_LIST, parms)
                        break
                    case SSI_EVENTS.GROUP_LIST:
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.GROUP_LIST_CHANGED:
                        if (state.located) dispatch(ACT_TETRA_LOCATE)
                        commit(MUT_TETRA_SET_LOCATED, {state: false})
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.CALL_EVENT:
                        const { cp } = parms
                        const {dir, callEvent, callType} = cp || {}
                        switch (callType) {
                            case CALL_TYPES.GROUP:
                                if (dir && callEvent) commit(MUT_TETRA_SET_CALL, parms.cp)
                                else commit(MUT_TETRA_SET_CALL, null)
                                break
                            case CALL_TYPES.DUPLEX:
                                dispatch(ACT_TETRA_HANDLE_DUPLEX_CALL_EVENT, parms)
                                break
                            case CALL_TYPES.SIMPLEX:
                                break
                        }
                        break
                    case SSI_EVENTS.SPEECH: {
                        const {ssi, ct: codec, sd: speech} = parms
                        event_bus.$emit('radio-speech', {duplex: Boolean(ssi), codec, speech})
                        break
                    }
                    case SSI_EVENTS.SEARCH_USERS:
                        const {list: searchedUsersList} = parms
                        commit(MUT_TETRA_SET_SEARCHED_USERS, searchedUsersList)
                        searchedUsersList.forEach(item => {
                            if (SSI_SEARCH_AWAITING[item.ssi]) SSI_SEARCH_AWAITING[item.ssi].resolve(item)
                        })
                        break
                    case SSI_EVENTS.GROUP_USERS:
                        commit(MUT_TETRA_SET_GROUP_USERS, parms)
                        break
                    case SSI_EVENTS.USER_STATUS:
                        commit(MUT_TETRA_SET_USER_STATUS, parms)
                        break
                    case SSI_EVENTS.MESSAGE: {
                        const {ssi} = parms
                        if (ssi) {
                            let list = [], type = 'msg'
                            list.push(ssi)
                            await dispatch(ACT_TETRA_UPDATE_USERS_LIST, {list, type})
                        }
                        const in_msg = Object.assign({}, parms)
                        in_msg.direction = 'in'
                        in_msg.status = 1
                        commit(MUT_TETRA_ADD_MESSAGE, in_msg)
                        const openedChatSsi = getters[GET_TETRA_OPENED_CHAT_SSI]
                        if (openedChatSsi.toString() !== ssi.toString()) {
                            setTimeout(() => {
                                dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {
                                    type: 'tetra-message',
                                    msg: in_msg
                                }, {root: true})
                            }, 300)
                        }
                        break
                    }
                    case SSI_EVENTS.MESSAGE_STATUS:
                        commit(MUT_TETRA_SET_MESSAGE_STATUS, parms)
                        break
                }
                break
            case MSG_LEVELS.READY:
                break
        }
    },

    [ACT_TETRA_HANDLE_DUPLEX_CALL_EVENT]: async ({dispatch, commit}, parms) => {
        const { cp } = parms
        const { dir, ssi, callEvent, txGrant } = cp || {}
        let isIncoming = dir === CALL_DIRECTION.INCOMING
        switch (callEvent) {
            case CALL_EVENTS.STARTING:
            case CALL_EVENTS.RINGING:
                //commit(MUT_TETRA_ADD_DUPLEX_CALL, parms.cp)
                const tetraUser = await dispatch(ACT_TETRA_SEARCH_USER_BY_SSI, parms.cp.ssi)
                if (isIncoming) {
                    dispatch(`${PHONE_CNTL}/${ACT_ADD_INCOMMING_CALL}`, {
                        id: ssi,
                        type: PHONE_TYPES.TETRA,
                        data: { tetraUser, ...parms.cp }
                    }, {root: true})
                }
                break
            case CALL_EVENTS.ANSWER:
                //commit(MUT_TETRA_ADD_DUPLEX_CALL, parms.cp)
                dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
                    id: ssi,
                    type: PHONE_TYPES.TETRA,
                    status: ANSWERED_CALL_STATUSES.TALK
                }, { root: true })
                break
            case CALL_EVENTS.RELEASE:
                //@todo
                //dispatch(`${PHONE_CNTL}/${ACT_TOGGLE_PHONE_MINIMIZE}`, {minimize : false}, {root: true})
                dispatch(ACT_TETRA_TERMINATE_DUPLEX_CALL, parms.cp)
                break
            case CALL_EVENTS.TX_GRANT:
            case CALL_EVENTS.CEASING:
                dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
                    id: ssi,
                    type: PHONE_TYPES.TETRA,
                    status: txGrant ? ANSWERED_CALL_STATUSES.ON_HOLD : ANSWERED_CALL_STATUSES.TALK
                }, { root: true })
                break
        }
    },
    [ACT_TETRA_SEARCH_USER_BY_SSI]: async ({dispatch}, ssi) => {
        return new Promise((resolve, reject) => {
            const parms = {filter: ssi, fromSSI: ssi, count: 1}
            dispatch(ACT_TETRA_SEARCH_USERS, parms)
            SSI_SEARCH_AWAITING[ssi] = { resolve, reject }
        })
    },
    [ACT_TETRA_TERMINATE_DUPLEX_CALL]: async ({state, commit, dispatch}, {ssi}) => {
        dispatch(`${PHONE_CNTL}/${ACT_HIDE_INCOMMING_CALL}`, { type: PHONE_TYPES.TETRA, id: ssi }, { root: true })
        dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_CALL}`, { type: PHONE_TYPES.TETRA, id: ssi }, { root: true })
    },
    [ACT_TETRA_MAKE_DUPLEX_CALL]: async ({state, dispatch}, {ssi}) => {
        const tetraUser = await dispatch(ACT_TETRA_SEARCH_USER_BY_SSI, ssi)
        const parms = {
            cp: {
                callEvent: CALL_EVENTS.STARTING,
                dir: CALL_DIRECTION.OUTGOING,
                callType: CALL_TYPES.DUPLEX,
                ssi,
            }
        }
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {
            msgType: SSI_EVENTS.CALL_EVENT,
            parms,
        })
        return { tetraUser, ...parms.cp }
        //commit(MUT_TETRA_ADD_DUPLEX_CALL, parms.cp)
    },
    [ACT_TETRA_ANSWER_DUPLEX_CALL]: async ({state, commit, dispatch}, { ssi }) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {
            msgType: SSI_EVENTS.CALL_EVENT,
            parms: {
                cp: {
                    callEvent: CALL_EVENTS.ANSWER,
                    dir: CALL_DIRECTION.INCOMING,
                    callType: CALL_TYPES.DUPLEX,
                    ssi,
                },
            },
        })
    },
    [ACT_TETRA_END_DUPLEX_CALL]: async ({state, commit, dispatch}, { ssi }) => {
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {
            msgType: SSI_EVENTS.CALL_EVENT,
            parms: {
                cp: {
                    callEvent: CALL_EVENTS.RELEASE,
                    callType: CALL_TYPES.DUPLEX,
                    ssi,
                }
            }
        })
        dispatch(ACT_TETRA_TERMINATE_DUPLEX_CALL, { ssi })
    },
    [ACT_TETRA_PAUSE]: async ({state, commit, dispatch}, { ssi, pause }) => {
        const txGrant = pause ? TX_GRANT.NOT_GRANTED : TX_GRANT.GRANTED
        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {
            msgType: SSI_EVENTS.CALL_EVENT,
            parms: {
                cp: {
                    callEvent: CALL_EVENTS.TX_GRANT,
                    callType: CALL_TYPES.DUPLEX,
                    txGrant,
                    ssi,
                }
            }
        })
        dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
            id: ssi,
            type: PHONE_TYPES.TETRA,
            status: pause ? ANSWERED_CALL_STATUSES.HOLD : ANSWERED_CALL_STATUSES.TALK
        }, { root: true })
    },
    [ACT_TETRA_SELECT_DUPLEX_CALL]: async ({dispatch, getters}, { ssi, active }) => {
        const call = getters[GET_TETRA_DUPLEX_CALL_BY_SSI](ssi)
        if (!call) return
        const allCalls = getters[GET_TETRA_DUPLEX_CALLS]
        allCalls.forEach(({id, status}) => {
            if (id !== ssi && status !== ANSWERED_CALL_STATUSES.HOLD) {
                dispatch(ACT_TETRA_PAUSE, {ssi: id, pause: true})
            }
        })
        dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
            type: PHONE_TYPES.TETRA,
            id: ssi,
            active: true,
        }, {root: true})
        if (active && call.status === ANSWERED_CALL_STATUSES.HOLD) {
            dispatch(ACT_TETRA_PAUSE, {ssi, pause: false})
        }
    },
    [ACT_TETRA_TOGGLE_MICROPHONE]: async ({dispatch, getters}, {ssi, state}) => {
        const call = getters[GET_TETRA_DUPLEX_CALL_BY_SSI](ssi)
        if (!call) return
        let micState = state === 'undefined' ? !call.micMute : state
        Vue.set(call, 'micMute', micState)
    },
    [ACT_TETRA_TRANSIT]: async ({dispatch, getters}) => {
        const allCalls = getters[GET_TETRA_DUPLEX_CALLS]
        if (allCalls.length < 2) return
        const activeCall = getters[GET_TETRA_ACTIVE_DUPLEX_CALL]
        if (!activeCall) return
        const notActiveCalls = allCalls.filter(call => call !== activeCall)
        let activeCallSsi = activeCall['id']
        let transferCallSsi = notActiveCalls[0] && notActiveCalls[0]['id']
        if (!activeCallSsi || !transferCallSsi) return

        dispatch(ACT_TETRA_API_SEND_SSI_CMD, {
            msgType: SSI_EVENTS.CALL_EVENT,
            parms: {
                cp: {
                    callEvent: CALL_EVENTS.TRANSFER,
                    callType: CALL_TYPES.DUPLEX,
                    ssi: activeCallSsi,
                    transfer: transferCallSsi,
                }
            }
        })
    },
}

const mutations = {
    [MUT_TETRA_SET_CLIENT_PARAMS]: (state, {name = null, ssi = null, life = null}) => {
        state.name = name
        state.ssi = ssi
        state.life = life
    },
    [MUT_TETRA_SET_CONNECT_STATUS]: (state, status = CONNECT_STATUS.OFFLINE) => {
        state.connectStatus = status
    },
    [MUT_TETRA_SET_CONNECT_ERROR]: (state, error = null) => {
        state.connectError = error
    },
    [MUT_TETRA_SET_LINK_STATUS]: (state, status = LINK_STATUS.CLOSED) => {
        state.linkStatus = status
    },
    [MUT_TETRA_SET_LOCATED]: (state, {status = false, cell = 0}) => {
        state.located = status
        state.rejected = false
        state.groupsAttached = false
        state.cell = cell
    },
    [MUT_TETRA_SET_REJECTED]: (state, rejected = false) => {
        state.rejected = rejected
        if (rejected) {
            state.located = false
            state.groupsAttached = false
            state.cell = 0
        }
    },
    [MUT_TETRA_SET_GROUPS_ATTACHED]: (state, groupsAttached = false) => {
        if (groupsAttached && !state.located) return
        state.groupsAttached = groupsAttached
    },
    [MUT_TETRA_SET_CALL]: (state, call = null) => {
        state.call = call
    },
    [MUT_TETRA_SET_TX_GRANT]: (state, txGrant = -1) => {
        state.txGrant = txGrant
    },
    [MUT_TETRA_SET_GROUPS]: (state, {actual = null, list = {}}) => {
        state.groups = list
        state.activeGroup = actual
    },
    [MUT_TETRA_SET_ACTIVE_GROUP]: (state, group) => {
        state.activeGroup = group
    },
    [MUT_TETRA_SET_PTT_STATUS]: (state, status) => {
        state.pttPushed = status
    },
    [MUT_TETRA_SET_TOKEN]: (state, token) => {
        state.token = token
    },
    [MUT_TETRA_SET_SEARCHED_USERS]: (state, usersList) => {
        state.searchedUsers = usersList
    },
    [MUT_TETRA_SET_USER_STATUS]: (state, parms) => {
        const {ssi, status} = parms
        Vue.set(state.usersStatuses, ssi, status)
    },
    [MUT_TETRA_SET_GROUP_USERS]: (state, parms) => {
        const {group, list} = parms
        Vue.set(state.groupUsers, group, list)
    },
    [MUT_TETRA_ADD_MESSAGE]: (state, message) => {
        // console.log("🚀 ~ file: tetra-radio.js:742 ~ message:", message)
        const { ssi } = message
        let messages = state.messages[ssi] || {}
        if (!Array.isArray(state.messages[ssi])) {
            const new_arr = []
            new_arr.push(message)
            Vue.set(state.messages, ssi, new_arr)
        }
        else messages.push(message)
        // console.log("🚀 ~ file: tetra-radio.js:751 ~ messages:", messages)
        if (state.openedChatSSI !== ssi && message.direction === 'in') {
            if (state.unwatchedMessages[ssi] && state.unwatchedMessages[ssi].count) {
                let value = state.unwatchedMessages[ssi].count + 1
                // console.log("🚀 ~ file: tetra-radio.js:755 ~ value:", value)
                Vue.set(state.unwatchedMessages, [ssi], {count: value, updated: date_helper.getCurrentUnixTime()})
            } else {
                Vue.set(state.unwatchedMessages, [ssi], {})
                state.unwatchedMessages[ssi].count = 1
                state.unwatchedMessages[ssi].updated = date_helper.getCurrentUnixTime()
                // console.log("🚀 ~ file: tetra-radio.js:759 ~ state.unwatchedMessages:", state.unwatchedMessages)
            }
        }
    },
    [MUT_TETRA_SET_MESSAGE_STATUS]: (state, parms) => {
        const { ssi, time, status, error } = parms
        const userMessages = state.messages[ssi] || {}
        const message = userMessages.find(m => m.time === time)
        if (!message) return
        Vue.set(message, 'status', status)
        if (error) Vue.set(message, 'error', error)
    },
    [MUT_TETRA_ADD_DUPLEX_CALL]: (state, call) => {
        let index = state.duplexCalls.findIndex(({ssi}) => call.ssi === ssi)
        if (index > -1) {
            state.duplexCalls.splice(index, 1, call)
        } else {
            state.duplexCalls.push(call)
        }
    },
    [MUT_TETRA_REMOVE_DUPLEX_CALL]: (state, call) => {
        let index = state.duplexCalls.findIndex(({ssi}) => call.ssi === ssi)
        if (index > -1) {
            state.duplexCalls.splice(index, 1)
        }
    },
    [MUT_TETRA_UPDATE_USERS_LIST]: (state, parms) => {
        let { list, type } = parms
        let currentList
        if (type && type !== 'init') currentList = state.usersList[type]
        if (type && type !== 'init' && !currentList) {
            Vue.set(state.usersList, [type], [])
            currentList = state.usersList[type]
        }
        if (currentList && list && list.length) {
            list.forEach(user => {
                const existedUser = currentList.find(u => u.ssi.toString() === user.ssi.toString())
                if (!existedUser) {
                    const newUser = {
                        ssi: user.ssi,
                        name: user.name || user.ssi,
                        updated: date_helper.getCurrentUnixTime()
                    }
                    currentList.push(newUser)
                }
            })
        }
        if (type === 'init') {
            const msgs = state.messages
            const contactList = state.usersList['contact']
            const msgList = state.usersList['msg']

            const excludedMsgSSIs = Object.keys(msgs).filter(k => !list.some(_u => _u.ssi.toString() === k.toString()))
            if (excludedMsgSSIs.length) excludedMsgSSIs.forEach(id => delete msgs[id])

            const excludedUsersContactList = contactList && contactList.filter(c => !list.some(_u => _u.ssi.toString() === c.ssi.toString()))
            if (excludedUsersContactList && excludedUsersContactList.length) {
                for (let i = 0; i < excludedUsersContactList.length; i++) {
                    const ind_ulist = contactList.findIndex(u => u.ssi.toString() === excludedUsersContactList[i].ssi.toString())
                    if (ind_ulist > -1) contactList.splice(ind_ulist, 1)
                }
            }

            const excludedUsersMsgList = msgList && msgList.filter(m => !list.some(_u => _u.ssi.toString() === m.ssi.toString()))
            if (excludedUsersMsgList && excludedUsersMsgList.length) {
                for (let i = 0; i < excludedUsersMsgList.length; i++) {
                    const ind_ulist = msgList.findIndex(u => u.ssi.toString() === excludedUsersMsgList[i].ssi.toString())
                    if (ind_ulist > -1) msgList.splice(ind_ulist, 1)
                }
            }

            list.forEach(el => {
                const userListContact = contactList.find(c => c.ssi.toString() === el.ssi.toString())
                if (userListContact) {
                    if (userListContact.name && el.name && userListContact.name !== el.name) userListContact.name = el.name
                    if (userListContact.note && el.note && userListContact.note !== el.name) userListContact.note = el.note
                }
                const userListMsgContact = msgList.find(c => c.ssi.toString() === el.ssi.toString())
                if (userListMsgContact) {
                    if (userListMsgContact.name && el.name && userListMsgContact.name !== el.name) userListMsgContact.name = el.name
                }
            })
            Vue.set(state.usersList, 'contact', contactList)
            Vue.set(state.usersList, 'msg', msgList)
        }
        else Vue.set(state.usersList, [type], currentList)
    },
    [MUT_TETRA_ADD_RECENT_USER]: (state, parms) => {
        // console.log("🚀 ~ file: tetra-radio.js:800 ~ parms:", parms)
        const { ssi, name } = parms
        if (!ssi) return
        let { type } = parms
        if (!type) type = 'contact'
        let currentList = state.usersList[type]
        if (!currentList) {
            Vue.set(state.usersList, [type], [])
            currentList = state.usersList[type]
        }
        const user = ssi && currentList && currentList.find(u => u.ssi === ssi)
        // console.log("🚀 ~ file: tetra-radio.js:809 ~ user:", user)
        if (user) {
            user.updated = date_helper.getCurrentUnixTime()
        }
        if (ssi && !user) {
            currentList.push({ssi, name, updated: date_helper.getCurrentUnixTime()})
        }
        // console.log("🚀 ~ file: tetra-radio.js:812 ~ currentList:", currentList)
    },
    [MUT_TETRA_CLEAR_UNWATCHED_MESSAGES]: (state, ssi) => {
        if (!ssi) return
        delete state.unwatchedMessages[ssi]
        Vue.set(state.unwatchedMessages, [ssi], {})
        state.unwatchedMessages[ssi].count = 0
    },
    [MUT_TETRA_SET_OPENED_CHAT_SSI]: (state, value) => {
        state.openedChatSSI = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
