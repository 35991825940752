var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "camera" }, [
    _c(
      "div",
      { staticClass: "camera-header" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("camera")))]),
        _c("custom-drop-down", {
          key: "1",
          ref: "select_camera",
          staticClass: "camera-select",
          attrs: {
            options: _vm.camerasList,
            default: _vm.selectedCamera,
            firstOption: "true",
            noOptions: _vm.$t("no-cameras-found"),
          },
          on: { "dd-selected-value": _vm.setActiveCamera },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "camera-video" }, [
      _vm.isDescriptionVisible
        ? _c("div", { attrs: { id: "videoDescription" } }, [
            _c("span", [_vm._v(_vm._s(_vm.videoDescription))]),
          ])
        : _vm._e(),
      _c("video", {
        ref: "video",
        attrs: { id: "localVideoWeb", autoplay: "", muted: "" },
        domProps: { muted: true },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }