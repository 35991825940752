var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chatsearchwrapper" },
    [
      _vm.isNoResults
        ? _c("list-item", {
            attrs: { title: _vm.noRecords, link: false, chevron: false },
          })
        : _vm._e(),
      _c("RecycleScroller", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.chats.length,
            expression: "chats.length",
          },
        ],
        ref: "scroller2",
        staticClass: "scroller",
        style: _vm.scrollerStyle,
        attrs: {
          id: "chat-search-area",
          items: _vm.foundChats,
          "item-size": _vm.itemSize,
          "key-field": "id",
          buffer: _vm.bufferSize,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "list-item",
                  {
                    staticClass: "listItemCustom",
                    attrs: {
                      header: _vm.getFio(item.senderId),
                      withHighlights: true,
                      highlights: item.highlights,
                      title: item.excerpt,
                      link: true,
                      chevron: false,
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.scrollToMessage(item.id)
                      },
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "media" },
                      [
                        _c("icon-image", {
                          attrs: {
                            src: _vm.getPhoto(item.senderId),
                            text: _vm.getFio(item.senderId),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("template", { slot: "after" }, [
                      _c("div", { staticClass: "dateTimeItems" }, [
                        _c("span", [_vm._v(_vm._s("" + item.date))]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _vm.isLoader
        ? _c(
            "div",
            { staticClass: "btnwrapper", style: _vm.btnWrapperStyle },
            [_c("content-loader")],
            1
          )
        : !_vm.isLookingFor && _vm.showSearchMore
        ? _c("div", { staticClass: "btnwrapper", style: _vm.btnWrapperStyle }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function ($event) {
                    return _vm.addMessages(true)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("media-search.search-more")))]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }