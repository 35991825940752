var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("channel-comp.comments"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "channel-comments" }, [
          _c("div", { staticClass: "body" }, [
            _c("div", { staticClass: "send-comment" }, [
              _c(
                "div",
                { staticClass: "send-comment-icon" },
                [
                  _c("icon-image", {
                    staticClass: "medium",
                    attrs: { src: _vm.user.photo, text: _vm.user.fio },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "send-comment-block" },
                [
                  _c("div", {
                    ref: "textarea",
                    staticClass: "textarea",
                    attrs: { contenteditable: "" },
                    on: {
                      focus: function ($event) {
                        _vm.textareaInFocus = true
                      },
                      input: _vm.onChangeInput,
                    },
                  }),
                  _vm.placeholder
                    ? _c("div", { staticClass: "placeholder-textarea" }, [
                        _vm._v(_vm._s(_vm.$t("channel-comp.your-comment"))),
                      ])
                    : _vm._e(),
                  _c("transition", { attrs: { name: "fade-btn" } }, [
                    _vm.textareaInFocus
                      ? _c(
                          "div",
                          {
                            staticClass: "send-comment-btn",
                            class: { "active-btn": _vm.input.length },
                            on: { click: _vm.addComment },
                          },
                          [_vm._v(_vm._s(_vm.$t("channel-comp.send")))]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { ref: "commentList", staticClass: "comments" },
              _vm._l(_vm.comments, function (comment) {
                return _c(
                  "div",
                  {
                    staticClass: "comment",
                    class: [
                      _vm.opacity ? "show-all" : "hide-all",
                      _vm.firstChildAnimation(),
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "send-comment-icon" },
                      [
                        _c("icon-image", {
                          staticClass: "medium",
                          attrs: {
                            src: comment.userPhoto,
                            text: comment.userFio,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "send-comment-block" },
                      [
                        _c("div", { staticClass: "send-comment-sec1" }, [
                          _c("div", { staticClass: "send-comment-msg-name" }, [
                            _vm._v(_vm._s(comment.userFio)),
                          ]),
                          _c("div", { staticClass: "send-comment-msg-time" }, [
                            _vm._v(_vm._s(comment.timeStr)),
                          ]),
                        ]),
                        _vm.checkReference(comment.text)
                          ? _c("reference-msg", {
                              attrs: {
                                textMsg: _vm.getTextEntities(comment.text)
                                  .outText,
                                textEntities: _vm.getTextEntities(comment.text)
                                  .entities,
                                modal: true,
                              },
                              on: { close: _vm.close },
                            })
                          : _c(
                              "div",
                              { staticClass: "send-comment-msg-text" },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(comment.textComment),
                                  },
                                }),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }