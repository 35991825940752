var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "enterconference" }, [
    _vm.integrationServer
      ? _c(
          "div",
          { staticClass: "enterway conf-item" },
          _vm._l(_vm.enterWays, function (option, index) {
            return _c(
              "div",
              {
                staticClass: "option",
                class: _vm.chosenEnterWay === index ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.chosenWayOptionClick(index)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(option.name))])]
            )
          }),
          0
        )
      : _vm._e(),
    !_vm.chosenEnterWay
      ? _c(
          "div",
          { staticClass: "conf-auth conf-item" },
          [
            _c("custom-input-text", {
              key: "0",
              ref: "enterConfID",
              attrs: {
                default_text: _vm.confID,
                input_text: _vm.confID,
                label: _vm.$t("videomost.conf-id"),
              },
              on: {
                "update:input_text": function ($event) {
                  _vm.confID = $event
                },
              },
            }),
            _c("custom-input-text", {
              key: "1",
              staticStyle: { "margin-left": "18px" },
              attrs: {
                default_text: _vm.confPassword,
                input_text: _vm.confPassword,
                label: _vm.$t("videomost.conf-password"),
              },
              on: {
                "update:input_text": function ($event) {
                  _vm.confPassword = $event
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.chosenEnterWay
      ? _c(
          "div",
          { staticClass: "linkway conf-item" },
          [
            _c("custom-input-text", {
              key: "2",
              attrs: {
                default_text: _vm.defTextLink,
                input_text: _vm.confLink,
                label: _vm.$t("videomost.conf-enterway-conflink"),
              },
              on: {
                "update:input_text": function ($event) {
                  _vm.confLink = $event
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "participant conf-item" },
      [
        _c("custom-input-text", {
          key: "3",
          attrs: {
            input_text: _vm.participantName,
            default_text: _vm.fio,
            label: _vm.$t("videomost.conf-participant-name"),
          },
          on: {
            "update:input_text": function ($event) {
              _vm.participantName = $event
            },
          },
        }),
      ],
      1
    ),
    _vm.showMediaToggleBtns
      ? _c(
          "div",
          { staticClass: "conf-modes conf-item" },
          [
            _c("custom-checkbox", {
              attrs: {
                default: _vm.isNoCamera,
                checkbox: _vm.isNoCamera,
                label: _vm.$t("videomost.conf-nocamera-on"),
              },
              on: {
                "update:checkbox": function ($event) {
                  _vm.isNoCamera = $event
                },
              },
            }),
            _c("custom-checkbox", {
              attrs: {
                default: _vm.isNoMicrophone,
                checkbox: _vm.isNoMicrophone,
                label: _vm.$t("videomost.conf-nomic-on"),
              },
              on: {
                "update:checkbox": function ($event) {
                  _vm.isNoMicrophone = $event
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }