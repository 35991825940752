var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-search-files", attrs: { id: "media-search-area" } },
    _vm._l(Object.keys(_vm.messages), function (key) {
      return _c("div", { staticClass: "months-group" }, [
        _c("span", { staticClass: "months-name" }, [_vm._v(_vm._s(key))]),
        _c(
          "div",
          { staticClass: "files-wrapper" },
          _vm._l(_vm.prepareMessages(_vm.messages[key]), function (message, i) {
            return _c("chat-regular-file-message", {
              key: i,
              staticClass: "media-file-search",
              attrs: { message: message, showFullDate: true, showSize: true },
              nativeOn: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.onContextMenu($event, message)
                },
              },
            })
          }),
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }