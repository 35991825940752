var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog hoy",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.conf-edit-conf"),
      },
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("confSettingsBase", {
            ref: "settings",
            attrs: { conf: _vm.conf, fieldsToShow: _vm.fieldsToShow },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("div", { staticClass: "conf-settings conf-item" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openExtendedSettings(_vm.conf)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.confSettingsText))]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: { click: _vm.modalClose },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.save") },
            on: {
              click: function ($event) {
                return _vm.confirmConfChange(_vm.conf)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }