var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-info" },
    [
      _c(
        "info-main-block",
        {
          staticClass: "contact-main-block",
          attrs: {
            "icon-photo": _vm.contact.photo || "",
            "icon-text": _vm.contact.fio,
            "common-text": _vm.fio,
            "common-title": _vm.title,
            "additional-text": _vm.statusText,
            official: _vm.official,
            isAllowedToViewContact: _vm.isViewProfileAllowed,
            edit: _vm.editable,
            "icon-viewer": "cid" in _vm.contact,
            "icon-type": "contact",
            "icon-id": _vm.cid,
            favorites: _vm.isViewProfileAllowed,
            "fav-active": _vm.favActive,
          },
          on: { edit: _vm.doShowContactEditor, fav: _vm.changeFavorites },
        },
        [
          _vm.serverDisplayName
            ? _c(
                "div",
                {
                  staticClass: "additional-text",
                  attrs: { slot: "after-common-text" },
                  slot: "after-common-text",
                },
                [_c("p", [_vm._v(_vm._s(_vm.serverDisplayName))])]
              )
            : _vm._e(),
          _vm.isViewProfileAllowed
            ? _c(
                "div",
                {
                  staticClass: "contact-btns",
                  attrs: { slot: "after-additional-text" },
                  slot: "after-additional-text",
                },
                [
                  _vm.params.cid || _vm.params.isGroup
                    ? _c(
                        "info-icon",
                        {
                          staticClass: "main-btn",
                          class: _vm.btnClass,
                          attrs: {
                            title: _vm.$t("information.message"),
                            disabled: !_vm.isChatAllowed,
                            background: true,
                            link: true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.isChatAllowed ? _vm.openChat(_vm.params) : ""
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-comment i-special",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "info-icon",
                    {
                      staticClass: "main-btn",
                      class: _vm.btnClass,
                      attrs: {
                        title: !_vm.isCallBtnEnabled
                          ? _vm.$t("calls-prohibited")
                          : _vm.$t("mainPage.voice-call"),
                        disabled: !_vm.isCallBtnEnabled || !_vm.isCallAllowed,
                        background: true,
                        link: true,
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.isCallBtnEnabled ? _vm.doCall("audio") : ""
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-phone",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _c(
                    "info-icon",
                    {
                      staticClass: "main-btn",
                      class: _vm.btnClass,
                      attrs: {
                        title: !_vm.isVideoBtnEnabled
                          ? _vm.$t("calls-prohibited")
                          : _vm.$t("mainPage.video-call"),
                        disabled: !_vm.isVideoBtnEnabled || !_vm.isCallAllowed,
                        background: true,
                        link: true,
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.isVideoBtnEnabled ? _vm.doCall("video") : ""
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-video i-special",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _c(
                    "info-icon",
                    {
                      staticClass: "main-btn",
                      class: _vm.btnClass,
                      attrs: {
                        title: _vm.$t("details"),
                        background: true,
                        link: true,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.doOpenDetailedInfo($event)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-info",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.isViewProfileAllowed && (_vm.cid || _vm.fields)
        ? [
            _vm.cid
              ? _c("acs-status", {
                  staticClass: "acs-status-info",
                  attrs: {
                    contact_id: _vm.cid,
                    cidInAcs: _vm.cidInAcs,
                    loading: _vm.acsLoading,
                  },
                })
              : _vm._e(),
            _vm.contactVisible
              ? _c(
                  "actions-container",
                  { staticClass: "contacts" },
                  [
                    _vm._l(_vm.phones, function (phone, i) {
                      return [
                        [
                          _c(
                            "list-item",
                            {
                              staticClass: "work-call",
                              attrs: {
                                slot: "action",
                                title: _vm.formatPhoneNumber(phone.value),
                                link: true,
                                noHover: _vm.isRoschatCalls,
                                chevron: false,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.isRoschatCalls ||
                                  !_vm.isCallBtnEnabled ||
                                  !_vm.isCallAllowed
                                    ? ""
                                    : _vm.doCall("number", phone.value)
                                },
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.doContextMenu($event, phone.value)
                                },
                              },
                              slot: "action",
                            },
                            [
                              _c(
                                "template",
                                { slot: "media" },
                                [
                                  _c(
                                    "info-icon",
                                    {
                                      staticClass: "contact-icon-wrapper",
                                      attrs: {
                                        background: false,
                                        disabled:
                                          !_vm.isCallBtnEnabled ||
                                          _vm.isRoschatCalls ||
                                          !_vm.isCallAllowed,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-phone",
                                        attrs: { slot: "default" },
                                        slot: "default",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                      ]
                    }),
                    _vm._l(_vm.mails, function (mail, i) {
                      return [
                        [
                          _c(
                            "list-item",
                            {
                              staticClass: "mail-send",
                              attrs: {
                                slot: "action",
                                title: mail.value,
                                link: true,
                                chevron: false,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.doSendMail(mail.value)
                                },
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.doContextMenu($event, mail.value)
                                },
                              },
                              slot: "action",
                            },
                            [
                              _c(
                                "info-icon",
                                {
                                  staticClass: "contact-icon-wrapper",
                                  attrs: { slot: "media", background: false },
                                  slot: "media",
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-envelope",
                                    attrs: { slot: "default" },
                                    slot: "default",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.showMute
              ? _c(
                  "actions-container",
                  [
                    _c("chat-mute-switch", {
                      attrs: {
                        slot: "action",
                        cid: _vm.cid,
                        cidType: _vm.cidType,
                      },
                      slot: "action",
                    }),
                    _c("chat-pin-switch", {
                      attrs: {
                        slot: "action",
                        cid: _vm.cid,
                        cidType: _vm.cidType,
                      },
                      slot: "action",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.isViewProfileAllowed && !_vm.contact.cid && _vm.contact.fields
        ? _c(
            "actions-container",
            [
              _c(
                "list-item",
                {
                  staticClass: "color-blue",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.create-new-contact"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.createNewContact($event)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "var(--icon-special-color)",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-user-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "list-item",
                {
                  staticClass: "color-blue",
                  attrs: {
                    slot: "action",
                    title: _vm.$t("mainPage.add-to-contact"),
                    link: true,
                    chevron: false,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addToExistedContact($event)
                    },
                  },
                  slot: "action",
                },
                [
                  _c(
                    "info-icon",
                    {
                      attrs: {
                        slot: "media",
                        "icon-color": "var(--icon-special-color)",
                        "position-left": true,
                      },
                      slot: "media",
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-file-user",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "actions-container",
        [
          _c(
            "list-item",
            {
              staticClass: "color-blue",
              attrs: {
                slot: "action",
                title: _vm.$t("mainPage.common-chats"),
                link: true,
                chevron: false,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.openCommonChats($event)
                },
              },
              slot: "action",
            },
            [
              _c(
                "info-icon",
                {
                  attrs: {
                    slot: "media",
                    "icon-color": "var(--icon-special-color)",
                    "position-left": true,
                  },
                  slot: "media",
                },
                [
                  _c("i", {
                    staticClass: "fal fa-users",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isViewProfileAllowed
        ? _c("media-search-info-items", {
            on: { opensearchmedia: _vm.openSearchMedia },
          })
        : _vm._e(),
      _vm.isViewProfileAllowed &&
      (!_vm.canBack || _vm.isCompactMode) &&
      _vm.inChatList
        ? _c(
            "actions-container",
            [
              _vm.isViewProfileAllowed
                ? _c(
                    "list-item",
                    {
                      staticClass: "color-red",
                      attrs: {
                        slot: "action",
                        title: _vm.$t("mainPage.delete-chat"),
                        link: true,
                        chevron: false,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.doDeleteChat($event)
                        },
                      },
                      slot: "action",
                    },
                    [
                      _c(
                        "info-icon",
                        {
                          attrs: {
                            slot: "media",
                            "icon-color": "inherit",
                            "position-left": true,
                          },
                          slot: "media",
                        },
                        [
                          _c("i", {
                            staticClass: "fal fa-trash-alt",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }