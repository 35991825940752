var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.enter-conference"),
      },
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("enter-conference-body", {
            ref: "enterConfBody",
            attrs: {
              type: _vm.type,
              vcLink: _vm.vcLink,
              fio: _vm.fio,
              integrationServer: _vm.integrationServer,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: { click: _vm.modalClose },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.enter") },
            on: { click: _vm.onOk },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }