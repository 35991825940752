var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCalendar" },
    [
      _c("custom-checkbox", {
        attrs: {
          default: _vm.isAddCalendar,
          checkbox: _vm.isAddCalendar,
          label: _vm.$t("videoconf.add-to-calendar"),
        },
        on: {
          "update:checkbox": function ($event) {
            _vm.isAddCalendar = $event
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAddCalendar,
              expression: "isAddCalendar",
            },
          ],
          staticClass: "people",
        },
        [
          _c("div", { staticClass: "people-label" }, [
            _c("span", [_vm._v("E-mails")]),
            _c("div", { staticClass: "lbl-chosen-people" }, [
              _vm._v("( " + _vm._s(_vm.attendees.length) + " )"),
            ]),
          ]),
          _c("div", { staticClass: "people-choose" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mailsStr,
                  expression: "mailsStr",
                },
              ],
              staticClass: "ctipeople",
              attrs: { label: "" },
              domProps: { value: _vm.mailsStr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.mailsStr = $event.target.value
                },
              },
            }),
            _c("i", {
              staticClass: "fa fa-user-plus lblBtn",
              on: {
                click: function ($event) {
                  return _vm.selectContacts()
                },
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }