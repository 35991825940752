//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as Comlink from 'comlink'
import RadioRecorder from './RadioRecorder'
import RadioPlayer from './RadioPlayer'
import SpeechSendLimiter from './SpeechSendLimiter'
//import {mergeBuffers, getWavMeta, downloadAsFile, testReadWavFile, down, testReadRawFile, mapFloat32ToUInt16Array, testReadEncFile, base64ToAb} from './MyTestFunctions'
import {errors as recorderErrors, events as recorderEvents} from './RadioRecorder'
import event_bus from "../../eventBus"
import { mapActions, mapGetters } from 'vuex'
import {
    PHONE_CNTL,
    RADIO,
    USERDATA,
} from '../../store/modulesNames'
import {
    ACT_SET_SPEECH_PACK_RATE,
    ACT_SET_SPEECH_BUFFER_EMPTY,
    ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY,
    ACT_SET_MEDIA_DEVICE_LAST_OK,
} from '../../store/actionsTypes'

import PerTimeCounter from "../../common/PerTimeCounter"
import {
    GET_ACTIVE_SPEAKERS, GET_ANSWERED_CALL,
    GET_PTT_POP_AWAITING,
    GET_SPEECH_BUFFER_EMPTY,
    GET_VOLUME_AUDIO,
} from '../../store/gettersTypes'
import {ANSWERED_CALL_STATUSES} from '../../constants'

const TETRA_BYTES_PER_ELEMENT = 2

const OUT_SAMPLE_RATE = 8000
const OUT_SAMPLE_SIZE = 480

const speechSendLimiter = new SpeechSendLimiter({})
const duplexSpeechSendLimiter = new SpeechSendLimiter({})

export default {
    name: "RadioSpeech",
    data() {
        return {
            cleanBuf: [], //todo test
            resampledBuf: [], //todo test
            echoBuf: [],
            testBuf: [], //todo test
            stopPlayTimeout: 0,
            stopPlayDuplexTimeout: 0,
            speechPackRate: new PerTimeCounter(),
            speechDuplexPackRate: new PerTimeCounter(),
            speechDuplexSilenceIntervals: {},
        }
    },
    computed: {
        // *** Переопределить в дочернем компоненте *** //
        isOwnCall: () => false,
        granted:() => false,
        pttPushed: () => false,
        radioIsTurnOn: () => false,
        activeDuplexCall: () => false,
        getEcho: () => 0,
        speakerId() {
            return this[GET_ACTIVE_SPEAKERS] && this[GET_ACTIVE_SPEAKERS].deviceId
        },
        speakerVolume() {
            return this[GET_VOLUME_AUDIO]
        },
        duplexCallSendSpeech() {
            const call = this.activeDuplexCall
            if (call) {
                return call.status === ANSWERED_CALL_STATUSES.TALK && !call.micMute
            }
            return false
        },
        duplexCallSendSilence() {
            const call = this.activeDuplexCall
            if (call) {
                return !!call.micMute
            }
            return false
        },
        ...mapGetters(RADIO, [GET_PTT_POP_AWAITING, GET_SPEECH_BUFFER_EMPTY]),
        ...mapGetters(USERDATA, [GET_ACTIVE_SPEAKERS, GET_VOLUME_AUDIO]),
        ...mapGetters(PHONE_CNTL, [GET_ANSWERED_CALL]),

        // *** Тестовые свойства *** //
        pttTestText: () => this.pttPushed ? 'popPtt' : 'pushPtt', //@todo test
    },
    methods: {
        // *** Переопределить в дочернем компоненте *** //
        sendSpeech:(speech) => {},
        sendDuplexSpeech:(speech) => {},
        subscribeOnIncomingSpeech:() => {},
        popPtt: () => {}, //@todo test
        pushPtt: () => {}, //@todo test

        repeatLast() {
            this.echoBuf.forEach(({speech}) => this.onIncomingSpeech({speech, repeat: true}))
        },
        async onIncomingSpeech({speech, repeat, duplex}) {
            if (duplex) {
                this.onIncomingDuplexSpeech({speech})
            } else {
                this.onIncomingGroupSpeech({speech, repeat})
            }
        },
        async onIncomingGroupSpeech({speech, repeat}) {
            if (!this.audioPlayer) return
            if (!repeat) {
                this.speechPackRate.increce()
                this.echoBuf.push({speech})
            }
            this.audioPlayer.addSample(speech)
            if (!this.stopPlayTimeout) {
                this.startPlaying()
                if (!repeat) this.echoBuf = []
            } else {
                this.updateStopTimeout()
            }
        },
        async onIncomingDuplexSpeech({speech}) {
            if (!this.duplexAudioPlayer) return
            this.duplexAudioPlayer.addSample(speech)
            if (!this.stopPlayDuplexTimeout) {
                this.startPlayingDuplex()
            } else {
                this.updateStopDuplexTimeout()
            }
        },
        async startPlaying() {
            this.stopPlayTimeout = 1
            try {
                await this.audioPlayer.start()
            } catch (e) {}
            this.updateStopTimeout()
        },
        async startPlayingDuplex() {
            this.stopPlayDuplexTimeout = 1
            try {
                await this.duplexAudioPlayer.start()
            } catch (e) {}
            this.updateStopDuplexTimeout()
        },
        updateStopTimeout() {
            clearTimeout(this.stopPlayTimeout)
            this.stopPlayTimeout = setTimeout(this.stopPlaying, 2000)
        },
        updateStopDuplexTimeout() {
            clearTimeout(this.stopPlayDuplexTimeout)
            this.stopPlayDuplexTimeout = setTimeout(this.stopPlayingDuplex, 2000)
        },
        stopPlaying() {
            if (!this.audioPlayer.outBuff.length) {
                clearTimeout(this.stopPlayTimeout)
                this.stopPlayTimeout = 0
                this.audioPlayer.stop()
            }
        },
        stopPlayingDuplex() {
            if (!this.duplexAudioPlayer.outBuff.length) {
                clearTimeout(this.stopPlayDuplexTimeout)
                this.stopPlayDuplexTimeout = 0
                this.duplexAudioPlayer.stop()
            }
        },
        async on() {
            const TetraCodec = Comlink.wrap(new Worker('js/app/webworkers/TetraCodec.js'))
            const AudioResampler = Comlink.wrap(new Worker('js/app/webworkers/AudioResampler.js'))

            this.audioRecorder = new RadioRecorder({
                outSampleRate: OUT_SAMPLE_RATE,
                outSampleLength: OUT_SAMPLE_SIZE,
                codecWorker: await new TetraCodec(),
                audioResampler: await new AudioResampler(),
                codec: 'tetra'
            })

            this.audioPlayer = new RadioPlayer({
                inSampleRate: OUT_SAMPLE_RATE,
                inSampleLength: OUT_SAMPLE_SIZE,
                codecWorker: await new TetraCodec(),
                audioResampler: await new AudioResampler(),
                codec: 'tetra',
                speakerId: this.speakerId,
                speakerVolume: this.speakerVolume,
            })

            this.duplexAudioRecorder = new RadioRecorder({
                outSampleRate: OUT_SAMPLE_RATE,
                outSampleLength: OUT_SAMPLE_SIZE,
                codecWorker: await new TetraCodec(),
                audioResampler: await new AudioResampler(),
                codec: 'tetra'
            })

            this.duplexAudioPlayer = new RadioPlayer({
                inSampleRate: OUT_SAMPLE_RATE,
                inSampleLength: OUT_SAMPLE_SIZE,
                codecWorker: await new TetraCodec(),
                audioResampler: await new AudioResampler(),
                codec: 'tetra',
                speakerId: this.speakerId,
                speakerVolume: this.speakerVolume,
            })

            this.audioRecorder.on(recorderEvents.encodedSample, (speech) => {
                //console.log(`${moment().format("HH:MM:ss.SSS")} speechFromMicrophone `, speech) //@todo test
                speechSendLimiter.push(speech)
            })

            this.duplexAudioRecorder.on(recorderEvents.encodedSample, (speech) => {
                //console.log(`${moment().format("HH:MM:ss.SSS")} speechFromMicrophone `, speech) //@todo test
                duplexSpeechSendLimiter.push(speech)
            })
        },
        off() {
            this.audioPlayer && this.stopPlaying()
            this.audioRecorder && this.audioRecorder.close()
            this.audioPlayer && this.audioPlayer.close()
            this.duplexAudioPlayer && this.duplexAudioPlayer.stopPlaying()
            this.duplexAudioRecorder && this.duplexAudioRecorder.close()
            this.duplexAudioPlayer && this.duplexAudioPlayer.close()
        },
        ...mapActions(RADIO, [ACT_SET_SPEECH_PACK_RATE, ACT_SET_SPEECH_BUFFER_EMPTY, ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY]),

        // *** Тестовые функции ***
        async downloadCleanSpeech() { //@todo test
            if (!this.cleanBuf.length) {
                this.audioRecorder.on(recorderEvents.microphoneSample, (speech) => {
                    this.cleanBuf.push(speech)
                })
                return this.audioRecorder.start()
            }
            this.audioRecorder.off(recorderEvents.microphoneSample)
            this.audioRecorder.stop()
            let sum = this.cleanBuf.map((item) => mapFloat32ToUInt16Array(item))
            let meta = getWavMeta({buffArray: sum, bytesPerSample: 2, sampleRate: 48000})
            sum.unshift(meta)
            downloadAsFile({buffArray: sum, fileName: 'test.wav'})
            this.cleanBuf = []
        },
        async downloadResampledSpeech() { //@todo test
            if (!this.resampledBuf.length) {
                this.audioRecorder.on(recorderEvents.resampledSample, (speech) => {
                    this.resampledBuf.push(speech)
                })
                return this.audioRecorder.start()
            }
            this.audioRecorder.off(recorderEvents.resampledSample)
            this.audioRecorder.stop()
            let sum = this.resampledBuf.map((item) => mapFloat32ToUInt16Array(item))
            let meta = getWavMeta({buffArray: sum, bytesPerSample: 2, sampleRate: 8000})
            sum.unshift(meta)
            downloadAsFile({buffArray: sum, fileName: 'test.wav'})
            this.resampledBuf = []
        },
        async encriptRawFileAndDownload() { //@todo test
            let samples = await testReadRawFile()
            if (!this.audioRecorder.codecWorker) this.audioRecorder.codecWorker = await new this.audioRecorder.CodecWorker()
            let sum = []
            while (samples.length) {
                sum.push(await this.audioRecorder.codecWorker._encode(samples.shift()))
            }

            //let sum = base64Array.map(item => base64ToAb(item))
            downloadAsFile({buffArray: sum, fileName: 'test.raw.enc'})
        },
        async playTestEncFile() { //@todo test
            let base64Array = await testReadEncFile()
            this.testBuf = base64Array
            this.emitFromEchoBuff()
        },
        emitFromEchoBuff() { //@todo test
            while (this.testBuf.length) {
                event_bus.$emit('radio-speech', {ssi: 7001, speech: this.testBuf.shift()})
            }
        },
        downloadEncFile() { //@todo test
            downloadAsFile({buffArray: this.testBuf.map(item => base64ToAb(item)), fileName: 'test.enc'})
        },
        togglePtt() { //@todo test
            this.pttPushed ? this.popPtt() : this.pushPtt()
        },
        ...mapActions(USERDATA, [ACT_SET_MEDIA_DEVICE_LAST_OK])
    },
    mounted () {
        speechSendLimiter.off('outgoingSample')
        speechSendLimiter.off('empty')
        speechSendLimiter.on('outgoingSample', (speech) => {
            this[ACT_SET_SPEECH_BUFFER_EMPTY](false)
            if (this.granted) {
                this.speechPackRate.increce()
                this.sendSpeech(speech)
            }
        })
        speechSendLimiter.on('empty', () => {
            this[ACT_SET_SPEECH_BUFFER_EMPTY](true)
        })

        duplexSpeechSendLimiter.off('outgoingSample')
        duplexSpeechSendLimiter.off('empty')
        duplexSpeechSendLimiter.on('outgoingSample', (speech) => {
            this[ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY](false)
            if (this.sendDuplexSpeech) {
                this.speechDuplexPackRate.increce()
                this.sendDuplexSpeech(speech)
            }
        })
        duplexSpeechSendLimiter.on('empty', () => {
            this[ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY](true)
        })

        this.subscribeOnIncomingSpeech()

        this.speechPackRate.on('currentRate', this[ACT_SET_SPEECH_PACK_RATE])
    },
    watch: {
        async duplexCallSendSpeech(cur, prev) {
            if (cur) {
                await this.duplexAudioRecorder.start()
            } else {
                this.duplexAudioRecorder.stop()
            }
        },
        async duplexCallSendSilence(cur, prev) {
            if (cur) {
                console.log('~~~~~~~~~ start', this.activeDuplexCall.id)
                this.speechDuplexSilenceIntervals[this.activeDuplexCall.id] = setInterval(() => {
                    duplexSpeechSendLimiter.push('dJlgJTMIrUoxFAgGPcUwNHfFEiUqs2Q8DwfMWG8GXpVyxQDA')
                }, 100)
            } else {
                console.log('~~~~~~~~~duplexCallSendSilence stop', this.activeDuplexCall.id)
                if (this.speechDuplexSilenceIntervals[this.activeDuplexCall.id]) clearInterval(this.speechDuplexSilenceIntervals[this.activeDuplexCall.id])
            }
        },
        radioIsTurnOn(state) {
            this.off()
            if (state) this.on()
        },
        async pttPushed (pushed, prev) {
            try {
                if (pushed) await this.audioRecorder.start()
                else this.audioRecorder.stop()
            } catch (e) {
                let text = this.$t('errors.unknown')
                switch (e && e.message) {
                    case recorderErrors.MICROPHONE_NOT_FOUND:
                        this[ACT_SET_MEDIA_DEVICE_LAST_OK]({deviceType: 'audio'})
                        text = this.$t('no-mic')
                        break
                }
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: text
                    }
                })
            }
        },
        [GET_PTT_POP_AWAITING](val) {
            if (val) this.audioRecorder.stop()
        },
        async getEcho() {
            this.repeatLast()
        },
        speakerId(val) {
            this.audioPlayer && this.audioPlayer.changeProps({ speakerId: val })
        },
        speakerVolume(val) {
            this.audioPlayer && this.audioPlayer.changeProps({ speakerVolume: val })
        },
    }
}
