//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Phone from "./Phone.vue"
import CustomAudio from "../custom/CustomAudio.vue"
import CustomVideo from "../custom/CustomVideo.vue"
import PhoneDialer from "./phone-dialer.vue"
import PhoneActionButton from "./PhoneActionButton.vue"
import PhoneFunctionButton from "./PhoneFunctionButton.vue"
import PhoneAudioIcons from "./PhoneAudioIcons.vue"
import CallsSelector from "./CallsSelector.vue"
import CallParticipants from "./PhoneParticipants.vue"
import SignalLevel from "../SignalLevel.vue"

//import CallParticipants from "../modal/CallParticipants.vue"
import { PHONE_BTN_TYPES } from "./PhoneFunctionButton.vue"
import { ACT_TOGGLE_PHONE_MINIMIZE } from '../../store/actionsTypes'
import { PHONE_CNTL, USERDATA } from "../../store/modulesNames"
import { GET_CALL_PARTICIPANTS, GET_CALL_TALK_TITLE, GET_VOLUME_RING } from "../../store/gettersTypes"

export default {
    name: "PhoneAudio",
    extends: Phone,
    components: {
        CustomAudio,
        CustomVideo,
        PhoneDialer,
        PhoneActionButton,
        PhoneFunctionButton,
        CallsSelector,
        PhoneAudioIcons,
        CallParticipants,
        SignalLevel,
    },
    created () {
        if (this.id) this.loaded = true
        this.onCreated && this.onCreated()
    },
    watch: {
       id(newVal) {
           this.loaded = Boolean(newVal)
       },
       ringVolume(val) {
            this.$refs.audio && (this.$refs.audio.volume = val)
        },
    },
    data() {
        return {
            keysDialer: false,
            participantsList: false,
            audioSrc: '',
            loaded: false,
            dialerDigits: '',
            dialerSyncMode: false,
        }
    },
    computed: {
        // *** Переопределить в дочернем компоненте start *** //
        btns() {
            return []
        },
        audioLoop() {
            return this.audioSrc !== 'audio/call_ring_second_alert.wav'
        },
        typeCallText() {
            return ''
        },
        // *** Переопределить в дочернем компоненте end *** //
        participants() {
            return this[GET_CALL_PARTICIPANTS](this.type, this.id)
        },
        isConf() {
            return this.participants.length > 1
        },
        talkTitle() {
            return this[GET_CALL_TALK_TITLE](this.type, this.id)
        },
        titleStyle() {
            if (this.isConf) {
                let fontSize = 20
                let charCount = this.talkTitle.length
                if (charCount > 40) {
                    let diff = Math.ceil((charCount - 40) / 8)
                    fontSize = fontSize * (Math.max(1 - (diff / 10), 0.5))
                }
                return {
                    'font-size': fontSize + 'px',
                }
            }
            return {}
        },
        qualityTitle() {
            return this.callQualityText
        },
        ringVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_RING}`] 
        },
        btnsCssClasses() {
            let classes = []
            if (this.busy) classes.push('busy')
            else if (this.btns.length <= 3) classes.push('onerow')
            return classes
        },
        ...mapGetters(PHONE_CNTL, [GET_CALL_PARTICIPANTS, GET_CALL_TALK_TITLE]),
    },
    methods: {
        minimizePhone () {
            this[ACT_TOGGLE_PHONE_MINIMIZE]()
        },
        // *** Переопределить в дочернем компоненте start *** //
        isFuncBtnActive (btnType) {
            return false
        },
        isFuncBtnDisabled (btnType) {
            return false
        },
        // *** Переопределить в дочернем компоненте end *** //
        getFuncBtnBindProps(btnType) {
            return {type: btnType, active: this.isFuncBtnActive(btnType), disable: this.isFuncBtnDisabled(btnType)}
        },
        onFuncBtnClick(btnType) {
            switch (btnType) {
                case PHONE_BTN_TYPES.PAUSE:
                    this.togglePause()
                    break
                case PHONE_BTN_TYPES.MICROPHONE:
                    this.toggleMicrophone()
                    break
                case PHONE_BTN_TYPES.SPEAKER:
                    this.toggleSpeaker()
                    break
                case PHONE_BTN_TYPES.MSG:
                    this.openChat()
                    break
                case PHONE_BTN_TYPES.TRANSIT:
                    this.doTransit()
                    break
                case PHONE_BTN_TYPES.CONFERENCE:
                    this.doConference()
                    break
                case PHONE_BTN_TYPES.NOTIFY:
                    this.subscribeOnFree()
                    break
                case PHONE_BTN_TYPES.CONTINUE:
                    this.continueTheCall()
                    break
                case PHONE_BTN_TYPES.BUTTONS:
                    this.keysDialerShow()
                    break
                case PHONE_BTN_TYPES.PARTICIPANTS:
                    this.showParticipants()
                    break
            }
        },
        showParticipants() {
            this.participantsList = true
        },
        keysDialerShow(syncMode, digits) {
            this.keysDialer = true
            if (syncMode) this.dialerSyncMode = syncMode
            if (digits) this.dialerDigits = digits
        },
        keysDialerHide() {
            this.keysDialer = false
            this.participantsList = false
            this.dialerSyncMode = false
            this.dialerDigits = ''
        },
        setAudioSrc(src = this.audioSrc) {
            if (this.audioSrc !== src) this.audioSrc = src
            this.$nextTick(() => {
                this.$refs.audio && (this.$refs.audio.volume = this.ringVolume)
            })
        }
    },
}
