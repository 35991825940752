var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statusText
    ? _c("div", { staticClass: "conf-status" }, [
        _c("div", { staticClass: "status-text" }, [
          _vm._v(_vm._s(_vm.statusText)),
        ]),
        _vm.startBtn
          ? _c("div", { staticClass: "buttons theme-videoconf" }, [
              _c(
                "div",
                { staticClass: "handup-wrapper", on: { click: _vm.startConf } },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("videomost.conf-start"))),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }