var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "manage-messages",
      class: { "selected-messages-active": _vm.isSelectMode },
    },
    [
      _vm.isSelectMode
        ? _c("div", { staticClass: "manage-messages-info" }, [
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  staticClass: "cancel-item",
                  attrs: { title: "Отменить выбор" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button circle-animation",
                      on: { click: _vm.clearMessagesSelection },
                    },
                    [_c("i", { staticClass: "fal fa-times" })]
                  ),
                ]
              ),
              _c("div", { staticClass: "selection-info" }, [
                _c("span", [
                  _vm._v("Выбрано: " + _vm._s(_vm.selectedMsgsNumber)),
                ]),
              ]),
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "div",
                  {
                    staticClass: "button circle-animation",
                    attrs: { title: "Удалить" },
                    on: { click: _vm.deleteMessages },
                  },
                  [_c("i", { staticClass: "fal fa-trash" })]
                ),
                _c(
                  "div",
                  {
                    staticClass: "button circle-animation",
                    attrs: { title: "Копировать" },
                    on: { click: _vm.copyMessages },
                  },
                  [_c("i", { staticClass: "fal fa-copy" })]
                ),
                _c(
                  "div",
                  {
                    staticClass: "button circle-animation",
                    attrs: { title: "Переслать" },
                    on: { click: _vm.forwardMessages },
                  },
                  [_c("i", { staticClass: "fal fa-share" })]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }